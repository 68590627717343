import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function useCollection(collection, on_error = (x) => x, conditions = (x) => x) {
  const db = useSelector((state) => state.firebase.db);
  const [doc_ref] = useState(conditions(db.collection(collection)));
  const [loading, set_loading] = useState(true);
  const [documents, set_documents] = useState();
  const [error, set_error] = useState();

  useEffect(() => {
    const unsubscribe = doc_ref.onSnapshot(
      (querySnapshot) => {
        const current_documents = querySnapshot.docs.map(
          (documentSnapshot) => ({
            ...documentSnapshot.data(),
            id: documentSnapshot.id,
            ref: documentSnapshot.ref,
          })
        );

        const changed =
          !documents || current_documents.length !== documents.length;

        if (changed) {
          set_documents(current_documents);
        }
      },
      (error) => {
        set_error(error);
      }
    );
    return unsubscribe;
  }, [doc_ref, documents]);

  useEffect(() => {
    if (documents) {
      set_loading(false);
    }
  }, [documents]);

  useEffect(() => {
    if (error) {
      on_error(error);
    }
  }, [error, on_error]);

  return { loading, documents, error };
}

export default useCollection;
