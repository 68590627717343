import { useEffect, useState, useRef, useMemo, useCallback } from 'react';

export default ({ onResize } = {}) => {
  const ref = useRef(null);
  const timeout_ref = useRef();
  const [width, set_width] = useState();
  const [height, set_height] = useState();

  const reference = useRef({
    width: undefined,
    height: undefined,
  });

  const resize_handler = useCallback(
    (element = ref.current) => {
      if (!element) {
        return;
      }

      // `Math.round` is in line with how CSS resolves sub-pixel values
      const newWidth = Math.round(element.offsetWidth);
      const newHeight = Math.round(element.offsetHeight);
      if (
        reference.current.width !== newWidth ||
        reference.current.height !== newHeight
      ) {
        if (timeout_ref) {
          clearTimeout(timeout_ref);
        }
        timeout_ref.current = setTimeout(() => {
          const newSize = { width: newWidth, height: newHeight };
          if (onResize) {
            onResize(newSize);
          } else {
            reference.current.width = newWidth;
            reference.current.height = newHeight;
            set_width(newWidth);
            set_height(newHeight);
          }
        }, 100);
      }
    },
    [ref, onResize]
  );

  useEffect(() => {
    const element = ref.current;

    if (!element) {
      return;
    }

    if (typeof ResizeObserver !== 'undefined') {
      const resizeObserver = new ResizeObserver(() => resize_handler(element));
      resizeObserver.observe(element);
      return () => {
        resizeObserver.unobserve(element);
      };
    } else {
      window.addEventListener('resize', resize_handler);
      return () => {
        window.removeEventListener('resize', resize_handler);
      };
    }
  }, [ref, onResize, resize_handler]);

  return useMemo(() => ({ ref, width, height }), [ref, width, height]);
};
