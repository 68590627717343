import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button, { ButtonGroup } from 'components/FancyButton';

const Admin = styled.section`
  padding: 0 2rem;
`;

const GameAdmin = ({
  current_story,
  set_current_story,
  max_story,
  go_to_summary,
}) => {
  const on_the_first_story = current_story === 0;
  const on_the_last_story = current_story === max_story;
  return (
    <Admin>
      <ButtonGroup>
        <Button
          center
          onClick={() => set_current_story(current_story - 1)}
          disabled={on_the_first_story}
        >
          <FontAwesomeIcon icon="backward" /> Previous story
        </Button>
        {on_the_last_story ? (
          <Button center onClick={() => go_to_summary()}>
            Finish! <FontAwesomeIcon icon="check-circle" />
          </Button>
        ) : (
          <Button center onClick={() => set_current_story(current_story + 1)}>
            Next story <FontAwesomeIcon icon="forward" />
          </Button>
        )}
      </ButtonGroup>
    </Admin>
  );
};

export default GameAdmin;
