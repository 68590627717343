import React from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button, { ButtonGroup } from 'components/FancyButton';
import Chip from 'components/Chip';
import ContentColumn from 'components/ContentColumn';

const Start = React.memo(({ game, update_game }) => {
  const user = useSelector((state) => state.user);
  const admin = user.uid === game.owner;

  const start_game = () => {
    update_game({ current_story: 0 });
  };

  return (
    <ContentColumn>
      <h1>
        {game.name}
        <Chip danger>Not started</Chip>
      </h1>

      <h2>Stories</h2>
      <ul>
        {Object.values(game.stories).map((story, index) => (
          <li key={index}>{story.title}</li>
        ))}
      </ul>

      <h2>Participants</h2>
      <ul>
        {Object.values(game.players)
          .filter((player) => player.present)
          .map((player) => (
            <li key={player.name}>{player.name}</li>
          ))}
      </ul>
      {admin && (
        <ButtonGroup main>
          <Button right onClick={start_game}>
            Let's play! <FontAwesomeIcon icon="play-circle" />
          </Button>
        </ButtonGroup>
      )}
    </ContentColumn>
  );
});

Start.whyDidYouRender = true;

export default Start;
