import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Game from 'models/Game';
import useGame from 'hooks/useGame';
import useConnection from 'hooks/useConnection';
import useDecks from 'hooks/useDecks';
import CreateStories from './CreateStories';
import Loading from 'components/Loading';
import Fieldset from 'components/Fieldset';
import Button, { ButtonGroup } from 'components/FancyButton';
import DeckSelector from 'components/Game/DeckSelector';
import Field from 'components/Field';
import ContentColumn from 'components/ContentColumn';
import Imports from './Imports';

const CreateGame = React.memo(() => {
  const navigate = useNavigate();
  const db = useSelector((state) => state.firebase.db);
  const [deck_id, set_deck_id] = useState(0);
  const [name, name_change] = useState('');
  const [stories, update_stories] = useState([]);
  const [imports_open, set_imports_open] = useState(false);
  const [creating, set_creating] = useState(false);
  const user = useSelector((state) => state.user);
  const { loading: loading_decks, documents: decks } = useDecks();
  const { create: create_game } = useGame();
  const { create: create_connection } = useConnection();

  const on_create_game = async () => {
    const game = new Game({ by: user, name, deck: decks[deck_id], stories });
    const connection = {
      link: db.collection('games').doc(game.id),
      owners: [db.collection('users').doc(user.uid)],
      participants: [],
      viewers: [],
      game: {
        deck: db.collection('decks').doc(game.deck.id),
        deck_name: game.deck.name,
        name: game.name,
      },
    };

    set_creating(true);

    await create_game(game);
    await create_connection(connection);

    navigate(`/games/${game.id}`);
  };

  const handle_import = (issues) => {
    set_imports_open(false);
    if (!issues) {
      return;
    }
    update_stories(issues);
  };

  if (loading_decks) {
    return <Loading>Loading decks</Loading>;
  }

  return (
    <ContentColumn>
      <h1>Create a new game!</h1>
      <Fieldset label="1" done={true}>
        <h2>Select what deck to use</h2>
        <DeckSelector onChange={set_deck_id} selected={deck_id} decks={decks} />
      </Fieldset>

      <Fieldset label="2" done={name !== ''}>
        <h2>Name it</h2>
        <Field
          value={name}
          onChange={name_change}
          placeholder="Set game name"
          label="Set a descriptive name for your game"
        />
      </Fieldset>

      <Fieldset label="3" done={stories.length > 0}>
        <CreateStories
          stories={stories}
          update_stories={update_stories}
          set_imports_open={set_imports_open}
        />
      </Fieldset>

      <ButtonGroup main>
        <Button
          onClick={on_create_game}
          disabled={name === '' || stories.length < 1 || creating}
        >
          And done! <FontAwesomeIcon icon="check" />
        </Button>
      </ButtonGroup>
      {imports_open && <Imports onClose={handle_import} />}
    </ContentColumn>
  );
});

export default CreateGame;
