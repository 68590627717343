import React from 'react';
import styled from 'styled-components';

import { Card } from 'components/Deck';

const Section = styled.section`
  display: flex;
  grid-area: cards;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: flex-end;
  max-width: 320px;
  margin: 0 auto;
  padding: 10rem 4rem;
  @media (min-width: 1200px) {
    margin: 0 auto;
  }
`;

const SizedCard = styled(Card)`
  & > div {
    background-color: var(--primary-light);
  }
`;

const Deck = ({ preview, deck, selected, onSelect }) => (
  <Section>
    <Card
      value={'?'}
      selected={false}
      color={'var(--primary)'}
      index={0}
      key={0}
      onClick={() => {}}
    />
    <SizedCard
      value={'!'}
      selected={false}
      color={'var(--primary)'}
      index={0}
      key={1}
      onClick={() => {}}
    />
  </Section>
);

export default Deck;
