import React from 'react';
import styled from 'styled-components'

const Backdrop = styled.section`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,.25);
`;

const Box = styled.div`
  width: 50%;
  background: var(--offwhite);
  border-radius: 4px;
  padding: 1rem 5rem 3rem 5rem;
  box-shadow: 1px 3px 0 0 #aaa, 0 0 100px 0 rgba(0,0,0,.25), 1px 5px 5px rgba(0,0,0,.25);
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #fff;
  max-height: 70%;
  overflow: hidden;
`;

const Close = styled.button`
  border:none;
  float: right;
  font-size: 20px;
  background: var(--red);
  background-image: linear-gradient(to top, var(--darkred), var(--red));
  width: 30px;
  margin-top: 1.33em;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  border-radius: 3px;
  border-top: 1px solid var(--darkred);
  box-shadow: 0 2px 0 0 var(--redshadow), 1px 3px 4px #aaa;
  color: var(--offwhite);
  text-shadow: 0px -1px 1px var(--redshadow);
  transition: box-shadow 250ms ease-out, color 250ms ease-out;
  &:hover {
    box-shadow: 0 2px 0 0 var(--redshadow), 1px 5px 5px #aaa;
    color: #fff;
    text-shadow: 0px -1px 1px var(--darkred);
  }
`;

const Dialog = ({ children, onClose }) => (
  <Backdrop>
    <Box>
      { onClose && <Close onClick={ onClose }>&#10005;</Close> }
      { children }
    </Box>
  </Backdrop>
)

export default Dialog;
