import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore, compose } from 'redux';

import { AUTH_STATE_CHANGED } from 'flows/user';
import createRootReducer from './reducers';
import createLogic from './logic';
import Firebase from './firebase';
import LogRocket from 'logrocket';

export const history = createBrowserHistory();
const firebase = new Firebase();

const enhancers = window.__REDUX_DEVTOOLS_EXTENSION__
  ? compose(
      applyMiddleware(
        createLogic(firebase, history, LogRocket.reduxMiddleware())
      ),
      window.__REDUX_DEVTOOLS_EXTENSION__()
    )
  : compose(
      applyMiddleware(
        createLogic(firebase, history, LogRocket.reduxMiddleware())
      )
    );

export default () => {
  const store = createStore(
    createRootReducer(history),
    {
      firebase,
    },
    enhancers
  );

  firebase.auth.onAuthStateChanged((user) =>
    store.dispatch({ type: AUTH_STATE_CHANGED, user })
  );

  return store;
};
