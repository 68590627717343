import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Wrapper = styled.span`
  &:first-child {
    margin-right: 1rem;
  }
  &:last-child {
    margin-left: 1rem;
  }
`;

const NavIcon = styled(FontAwesomeIcon)`
  font-size: 2.5rem;
  vertical-align: top;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'inherit')};
  color: ${(props) => (props.disabled ? '#ddd' : '#000')};
`;

const Span = styled.span`
  margin: 0 0.5rem;
`;

const InlineNavRocker = ({ position, length, next, previous }) => {
  if (length === 1) {
    return null;
  }

  return (
    <Wrapper>
      <NavIcon icon="caret-left" disabled={position === 1} onClick={previous} />
      <Span>
        {position} of {length}
      </Span>
      <NavIcon
        icon="caret-right"
        disabled={position === length}
        onClick={next}
      />
    </Wrapper>
  );
};

export default InlineNavRocker;
