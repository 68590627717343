import React from 'react';

import { Info } from 'components/IconButton';

const SectionHeader = ({ children, tooltip }) => (
  <h1>
    {children}
    {tooltip && (
      <>
        &nbsp;
        <Info tooltip={tooltip} left />
      </>
    )}
  </h1>
);

export default SectionHeader;
