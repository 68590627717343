import { library, config } from '@fortawesome/fontawesome-svg-core';
import { faGoogle, faMarkdown } from '@fortawesome/free-brands-svg-icons';
import {
  fas,
  faGhost,
  faUserCircle,
  faCaretLeft,
  faCaretRight,
  faBan,
  faChevronRight,
  faChevronLeft,
  faBackward,
  faCheckCircle,
  faForward,
  faCog,
  faQuestionCircle,
  faPlus,
  faCalendarDay,
  faCalendarWeek,
  faCalendarAlt,
  faUser,
  faCheck,
  faUserFriends,
  faUsers,
  faHome,
  faThumbtack,
  faPlayCircle,
  faCloudDownload,
  faCircle,
  faTrashAlt,
} from '@fortawesome/pro-solid-svg-icons';

import { faThumbtack as farThumbtack } from '@fortawesome/pro-regular-svg-icons';
import '@fortawesome/fontawesome-svg-core/styles.css';

config.autoAddCss = false;

library.add(
  fas,
  faGoogle,
  faMarkdown,
  faGhost,
  faUserCircle,
  faCaretLeft,
  faCaretRight,
  faBan,
  faChevronRight,
  faChevronLeft,
  faBackward,
  faCheckCircle,
  faForward,
  faCog,
  faQuestionCircle,
  faPlus,
  faCalendarDay,
  faCalendarWeek,
  faCalendarAlt,
  faUser,
  faCheck,
  faUserFriends,
  faUsers,
  faHome,
  faThumbtack,
  farThumbtack,
  faPlayCircle,
  faCloudDownload,
  faCircle,
  faTrashAlt
);
