import styled from 'styled-components'

const List = styled.ul`
  font-size: 1.5rem;
  list-style-type: none;
  padding: 0;
`;

export const ListItem = styled.li`
  padding: .5rem 1rem;
  margin: .5rem;
`;

List.Item = ListItem

export default List
