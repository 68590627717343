import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Loading from 'components/Loading';

const Page = React.memo(
  () => {
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch({
        type: 'ADD_ERROR',
        error: {
          message: `index.js:1406 Warning: validateDOMNesting(...): <pre> cannot appear as a descendant of <p>.
    in pre (created by Context.Consumer)
    in StyledComponent (created by styled.pre)
    in styled.pre (at ErrorDialog.js:47)
    in p (at ErrorDialog.js:36)
    in div (created by Context.Consumer)
    in StyledComponent (created by styled.div)
    in styled.div (at Dialog.js:53)
    in section (created by Context.Consumer)
    in StyledComponent (created by styled.section)
    in styled.section (at Dialog.js:52)
    in Dialog (at ErrorDialog.js:28)
    in ErrorDialog (at App.js:175)
    in section (created by Context.Consumer)
    in StyledComponent (created by styled.section)
    in styled.section (at App.js:123)
    in App (at src/index.js:23)
    in Router (created by ConnectedRouter)
    in ConnectedRouter (created by Context.Consumer)
    in ConnectedRouterWithContext (created by ConnectFunction)
    in ConnectFunction (at src/index.js:22)
    in Provider (at src/index.js:21)`,
        },
        location: 'pages/teams/Index.js',
      });

      dispatch({
        type: 'ADD_ERROR',
        error: {
          message: `Uncaught TypeError: Cannot read property 'message' of undefined
    at VM103546 main.chunk.js:1190
    at Array.map (<anonymous>)
    at ErrorDialog (VM103546 main.chunk.js:1172)
    at renderWithHooks (VM103545 1.chunk.js:77538)
    at updateFunctionComponent (VM103545 1.chunk.js:79717)
    at beginWork (VM103545 1.chunk.js:81242)
    at HTMLUnknownElement.callCallback (VM103545 1.chunk.js:62931)
    at Object.invokeGuardedCallbackDev (VM103545 1.chunk.js:62980)
    at invokeGuardedCallback (VM103545 1.chunk.js:63033)
    at beginWork$1 (VM103545 1.chunk.js:85794)
    at performUnitOfWork (VM103545 1.chunk.js:84758)
    at workLoopSync (VM103545 1.chunk.js:84734)
    at performSyncWorkOnRoot (VM103545 1.chunk.js:84352)
    at VM103545 1.chunk.js:73872
    at unstable_runWithPriority (VM103545 1.chunk.js:115093)
    at runWithPriority$1 (VM103545 1.chunk.js:73818)
    at flushSyncCallbackQueueImpl (VM103545 1.chunk.js:73867)
    at flushSyncCallbackQueue (VM103545 1.chunk.js:73855)
    at batchedUpdates$1 (VM103545 1.chunk.js:84458)
    at Object.notify (VM103545 1.chunk.js:91160)
    at Subscription.notifyNestedSubs (VM103545 1.chunk.js:91231)
    at Subscription.handleChangeWrapper (VM103545 1.chunk.js:91236)
    at Object.dispatch (VM103545 1.chunk.js:99087)
    at e (<anonymous>:1:40553)
    at SafeSubscriber._next (VM103545 1.chunk.js:98442)
    at SafeSubscriber.__tryOrUnsub (VM103545 1.chunk.js:101158)
    at SafeSubscriber.next (VM103545 1.chunk.js:101087)
    at Subscriber._next (VM103545 1.chunk.js:101019)
    at Subscriber.next (VM103545 1.chunk.js:100988)
    at MergeMapSubscriber.notifyNext (VM103545 1.chunk.js:107304)
    at InnerSubscriber._next (VM103545 1.chunk.js:100064)
    at InnerSubscriber.Subscriber.next (VM103545 1.chunk.js:100988)
    at FilterSubscriber._next (VM103545 1.chunk.js:106311)
    at FilterSubscriber.Subscriber.next (VM103545 1.chunk.js:100988)
    at RefCountSubscriber.Subscriber._next (VM103545 1.chunk.js:101019)
    at RefCountSubscriber.Subscriber.next (VM103545 1.chunk.js:100988)
    at Subject.next (VM103545 1.chunk.js:100712)
    at ConnectableSubscriber.Subscriber._next (VM103545 1.chunk.js:101019)
    at ConnectableSubscriber.Subscriber.next (VM103545 1.chunk.js:100988)
    at MergeMapSubscriber.notifyNext (VM103545 1.chunk.js:107304)
    at InnerSubscriber._next (VM103545 1.chunk.js:100064)
    at InnerSubscriber.Subscriber.next (VM103545 1.chunk.js:100988)
    at FilterSubscriber._next (VM103545 1.chunk.js:106311)
    at FilterSubscriber.Subscriber.next (VM103545 1.chunk.js:100988)
    at RefCountSubscriber.Subscriber._next (VM103545 1.chunk.js:101019)
    at RefCountSubscriber.Subscriber.next (VM103545 1.chunk.js:100988)
    at Subject.next (VM103545 1.chunk.js:100712)
    at ConnectableSubscriber.Subscriber._next (VM103545 1.chunk.js:101019)
    at ConnectableSubscriber.Subscriber.next (VM103545 1.chunk.js:100988)
    at Subject.next (VM103545 1.chunk.js:100712)`,
        },
        location: 'pages/teams/Index.js',
      });
    }, [dispatch]);

    return <Loading>Loading teams and invitations</Loading>;
  },
  () => true
);

Page.whyDidYouRender = true;

export default Page;
