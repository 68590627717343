import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/performance';

const config = {
  apiKey: 'AIzaSyCE7uv3B7L6UtUzujBSAucs8Uff75WFO9c',
  authDomain: 'plan-in-poker.firebaseapp.com',
  databaseURL: 'https://plan-in-poker.firebaseio.com',
  projectId: 'plan-in-poker',
  storageBucket: 'plan-in-poker.appspot.com',
  messagingSenderId: '512972662602',
  appId: '1:512972662602:web:d40b2ba01b68d2af',
};

class Firebase {
  constructor() {
    firebase.initializeApp(config);
    this.auth = firebase.auth();
    this.db = firebase.firestore();
    this.perf = firebase.performance();

    if (process.env.NODE_ENV !== 'development') {
      this.db.enablePersistence().catch(function (error) {
        console.log('Error enabling cache', error);
        if (error.code === 'failed-precondition') {
          // Multiple tabs open, persistence can only be enabled
          // in one tab at a a time.
          // ...
        } else if (error.code === 'unimplemented') {
          // The current browser does not support all of the
          // features required to enable persistence
          // ...
        }
      });
    }
  }
}

export default Firebase;
