import React from 'react';
import styled from 'styled-components';
import { v1 as uuid } from 'uuid';

const Label = styled.label`
  font-size: 1.5rem;
  margin: 1rem 0;
  display: inline-block;
  width: 100%;
`;

const Input = styled.textarea`
  width: 100%;
  padding: 0.5rem 3rem 0.5rem 2rem;
  font-size: 1.5rem;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: inset 0px -1px 0px 0px #ddd, inset 1px 2px 0px #ddd,
    inset 1px 3px 4px #555;
  line-height: 28px;
  border-top-color: #eee;
  border-bottom-color: #fff;
  &[disabled] {
    background-color: var(--grey);
    color: #888;
    pointer-events: none;
  }
  &:focus {
    outline: #5cbbbb auto 5px;
  }
  &:focus:hover {
    outline: #a0e6e6 auto 5px;
  }
`;

const Field = ({
  name = uuid(),
  value,
  type = 'text',
  onChange,
  label,
  placeholder,
  rows = 5,
}) => (
  <>
    {label ? <Label htmlFor={name}>{label}</Label> : null}
    <Input
      type={type}
      name={name}
      id={name}
      value={value}
      onChange={({ target: { value } }) => onChange(value)}
      placeholder={placeholder}
      rows={rows}
    />
  </>
);

export default Field;
