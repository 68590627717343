import React from 'react';

import ContentColumn from 'components/ContentColumn';

const Lobby = () => (
  <ContentColumn>
    <h1>Welcome</h1>
    <p>
      To start using the service simply go to "Games" in the menu at the top and
      click the "New Game" button at the bottom.
    </p>
  </ContentColumn>
);

export default Lobby;
