import {
  USER_LOADED,
  USER_UNLOAD,
  USER_UPDATE,
  USER_SET_NAME,
} from 'flows/user';

const initial_state = {
  uid: undefined,
  is_anonymous: undefined,
  name: 'Guest',
  photo_url: undefined,
  email: undefined,
  game: undefined,
  loading: true,
};

function user(state = initial_state, action) {
  switch (action.type) {
    case USER_LOADED:
      return { ...state, ...action.user, loading: false };
    case USER_UNLOAD:
      return { ...initial_state, loading: false };
    case USER_UPDATE:
      return { ...state, ...action.attributes };
    case USER_SET_NAME:
      state.name = action.name;
      return state;
    default:
      return state;
  }
}

export default user;
