import React, { useEffect } from 'react';
import { Route, Routes, useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Firebase from 'firebase/app';

import useGame from 'hooks/useGame';
import Loading from 'components/Loading';
import Start from './Start';
import Summary from './Summary';
import Hands from './Hands';

const Games = React.memo(() => {
  const { id } = useParams();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const { loading, document: game, update: update_game } = useGame(id);

  // Handle joining and leaving the game.
  useEffect(() => {
    const leave_game = () => {
      update_game({
        [`players.${user.uid}.present`]: false,
      });
    };

    // Not enough info to make a decision yet.
    if (!user || !game) {
      return;
    }

    // Got the info, are we already an active player in the game?
    if (game.players[user.uid] && game.players[user.uid].present) {
      return;
    }

    update_game({
      [`players.${user.uid}`]: {
        name: user.name,
        present: true,
        active: Firebase.firestore.FieldValue.serverTimestamp(),
      },
    });

    window.addEventListener('beforeunload', leave_game);

    return () => {
      window.removeEventListener('beforeunload', leave_game);
      if (!game.players[user.uid] || !game.players[user.uid].present) {
        return;
      }

      leave_game();
    };
  }, [user, update_game, game]);

  // Automatically navigate to the correct screen when the game updates
  useEffect(() => {
    if (game && game.current_story === Infinity) {
      navigate(`/games/${id}/summary`, { replace: true });
    } else if (game && game.current_story >= 0) {
      navigate(`/games/${id}/stories/${game.current_story}`, { replace: true });
    }
  }, [game, navigate, id]);

  if (loading) {
    return <Loading>Loading game</Loading>;
  }

  return (
    <Routes>
      <Route
        path="/"
        element={<Start game={game} update_game={update_game} />}
      />
      <Route
        path="summary"
        element={<Summary game={game} update_game={update_game} />}
      />
      <Route
        path="stories/:story_number"
        element={<Hands game={game} update_game={update_game} />}
      />
    </Routes>
  );
});

Games.whyDidYouRender = true;

export default Games;
