import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ContentColumn from 'components/ContentColumn';
import SectionHeader from 'components/SectionHeader';
import { ButtonGroup } from 'components/FancyButton';
import Chip from 'components/Chip';
import ToggleSwitch, { ToggleOption } from 'components/ToggleSwitch';

const Plans = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const emphasised = css`
  background-color: var(--primary);
  color: #fff;
  transform: scale(1.1);
`;

const deemphasised = css`
  opacity: 0.75;
  transform: scale(0.98);
`;

const Plan = styled.div`
  width: 33%;
  border: 1px solid transparent;
  border-radius: 3px;
  padding: 4rem 2rem 2rem 2rem;
  background-color: white;
  border-color: #dadada;
  box-shadow: 1px 3px 4px 0 var(--grey);
  margin: 2rem;
  display: grid;
  grid-auto-rows: auto auto auto 1fr auto;
  background-color: var(--primary-dark);
  color: var(--offwhite);
  ${(props) => props.emphasised && emphasised}
  ${(props) => props.deemphasised && deemphasised}
`;

const Periodicity = styled.div`
  font-size: 1.5rem;
  margin-bottom: 3rem;
  text-align: center;
`;

const CenteredFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin: 0 auto;
`;

const Header = styled.h2`
  text-align: center;
`;

const Tagline = styled.p`
  margin: 0;
`;

const Features = styled.div`
  font-size: 1.3rem;
  width: calc(100% + 4rem);
  position: relative;
  left: -2rem;
  background-color: #fff;
  color: var(--reallydarkgrey);
  margin: 2rem 0;
  padding: 0 2rem;
  & > ul {
    margin-left: 1.5em;
  }
`;

const Pricing = styled.p`
  margin: 0;
  display: ${(props) => (props.active ? 'block' : 'none')};
`;

const Price = styled.span`
  font-size: 5rem;
  display: block;
  text-align: center;
`;

const PriceDetails = styled.span`
  font-size: 1rem;
  display: block;
  text-align: center;
`;

const Button = styled(Chip)`
  line-height: 3rem;
  font-size: 2rem;
  padding: 1rem 4rem;
  margin: 2rem 0 0 0;
  pointer-events: inherit;
  width: 100%;
  text-align: center;
  cursor: pointer;
  user-select: none;
  &:link,
  &:active,
  &:visited {
    color: #fff;
  }
  &:hover {
    text-decoration: none;
  }
`;

const Disclaimers = styled.ol`
  margin-top: 3rem;
`;

const Page = () => {
  const [periodicity, set_periodicity] = useState('monthly');

  return (
    <ContentColumn>
      <SectionHeader>Select a plan</SectionHeader>
      <Periodicity>
        <ToggleSwitch onChange={set_periodicity} selected_value={periodicity}>
          <ToggleOption value="monthly">
            <FontAwesomeIcon icon="calendar-week" /> Monthly
          </ToggleOption>
          <ToggleOption value="yearly">
            <FontAwesomeIcon icon="calendar-alt" /> Yearly
          </ToggleOption>
        </ToggleSwitch>
      </Periodicity>
      <Plans>
        <Plan>
          <CenteredFontAwesomeIcon size="6x" icon="user" />
          <Header>Try it out</Header>
          <Pricing active={true}>
            <Price>Free</Price>
            <PriceDetails>&nbsp;</PriceDetails>
          </Pricing>
          <Features>
            <ul className="fa-ul">
              <li>
                <FontAwesomeIcon icon="check" listItem />
                Free sessions
              </li>
              <li>
                <FontAwesomeIcon icon="check" listItem />
                One concurrent session
              </li>
              <li>
                <FontAwesomeIcon icon="check" listItem />
                Maximum five participants per session
              </li>
              <li>
                <FontAwesomeIcon icon="check" listItem />
                Manual import/export of stories<sup>1</sup>
              </li>
            </ul>
          </Features>
          <Tagline>
            Suitable for a single, small team or just to try it out.
          </Tagline>
          <ButtonGroup>
            <Button center color="var(--secondary-light)">
              Select plan
            </Button>
          </ButtonGroup>
        </Plan>
        <Plan emphasised>
          <CenteredFontAwesomeIcon size="6x" icon="user-friends" />
          <Header>Single team</Header>
          <Pricing active={periodicity === 'monthly'}>
            <Price>€5</Price>
            <PriceDetails>per month per team</PriceDetails>
          </Pricing>
          <Pricing active={periodicity === 'yearly'}>
            <Price>€50</Price>
            <PriceDetails>per year per team</PriceDetails>
          </Pricing>
          <Features>
            <ul className="fa-ul">
              <li>
                <FontAwesomeIcon icon="check" listItem />
                Team management
              </li>
              <li>
                <FontAwesomeIcon icon="check" listItem />
                Unlimited concurrent sessions
              </li>
              <li>
                <FontAwesomeIcon icon="check" listItem />
                Maximum ten participants per session
              </li>
              <li>
                <FontAwesomeIcon icon="check" listItem />
                Automatic import/export of stories<sup>1</sup>
              </li>
            </ul>
          </Features>
          <Tagline>Suitable for a few teams or a small company.</Tagline>
          <ButtonGroup>
            <Button center color="var(--secondary-light)">
              Select plan!
            </Button>
          </ButtonGroup>
        </Plan>
        <Plan deemphasised>
          <CenteredFontAwesomeIcon size="6x" icon="users" />
          <Header>Company</Header>
          <Pricing active={true}>
            <Price>€-</Price>
            <PriceDetails>per month</PriceDetails>
          </Pricing>
          <Features>
            <ul className="fa-ul">
              <li>
                <FontAwesomeIcon icon="check" listItem />
                ...
              </li>
              <li>
                <FontAwesomeIcon icon="check" listItem />
                ..
              </li>
              <li>
                <FontAwesomeIcon icon="check" listItem />.
              </li>
            </ul>
          </Features>
          <Tagline>Suitable for large companies and organizations.</Tagline>
          <ButtonGroup>
            <Button
              as="a"
              center
              color="var(--secondary-light)"
              href="mailto:info@plan.with.poker?subject=Company%20tier%20beta%20access"
            >
              Request beta access
            </Button>
          </ButtonGroup>
        </Plan>
      </Plans>
      <Disclaimers>
        <li>
          Import currently supports Jira and Trello. More systems to follow,
          including: Github, Gitea and Bitbucket.
        </li>
      </Disclaimers>
    </ContentColumn>
  );
};

export default Page;
