import React from 'react';
import styled from 'styled-components';

import Card from './Card';

const Section = styled.section`
  font-size: ${(props) => 10 * props.scale}px;
  display: grid;
  grid-template-columns:
    auto repeat(${(props) => props.size}, minmax(0, min-content))
    auto;
  justify-content: space-around;
  align-items: flex-end;
  height: auto;

  padding: 0 4rem 1rem 4rem;
  ${(props) => props.preview && 'pointer-events: none;'};
  @media (max-width: 960px) {
    grid-template-columns:
      0 repeat(${(props) => props.size}, minmax(0, min-content))
      0;
  }
`;

const Spacer = styled.div`
  min-width: 0;
  overflow: hidden;
  max-height: 0;
  font-size: 100em;
`;

const Deck = React.forwardRef(
  ({ preview, deck, selected, onSelect, scale }, ref) => (
    <Section scale={scale} preview={preview} size={deck.cards.length} ref={ref}>
      <Spacer>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </Spacer>
      {deck.cards.map((card, index) => (
        <Card
          value={card.value}
          selected={index === selected}
          color={card.color}
          index={index}
          key={index}
          onClick={() => onSelect(index)}
        />
      ))}
      <Spacer>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </Spacer>
    </Section>
  )
);

export default Deck;
