import { useState } from 'react';

const useCookie = (name, default_value) => {
  const encoded_name = encodeURIComponent(name);

  const get_cookie = () => {
    const cookie_string = document.cookie;
    const cookie_strings = cookie_string.split(';').map((str) => str.trim());
    const cookies = cookie_strings.reduce((obj, str) => {
      const [key, value] = str.split('=');
      if (key === '') {
        return obj;
      }
      obj[key] = value;
      return obj;
    }, {});

    return cookies[encoded_name]
      ? decodeURIComponent(encoded_name)
      : default_value;
  };

  const [cookie, set_cookie_state] = useState(get_cookie());

  const set_cookie = (
    value = default_value,
    { max_age = 31536000, path = '/' } = {}
  ) => {
    const cookie_elements = [];

    cookie_elements.push(`${encoded_name}=${encodeURIComponent(value)}`);
    cookie_elements.push(`path=${path}`);
    cookie_elements.push(`maxAge=${max_age}`);
    if (window.location.protocol === 'https:') {
      cookie_elements.push('secure');
    }
    cookie_elements.push('samesite=strict');

    set_cookie_state(value);
    document.cookie = cookie_elements.join(';');
  };

  return [cookie, set_cookie];
};

export default useCookie;
