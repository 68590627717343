import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Route, Routes, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LogRocket from 'logrocket';

import useCookie from 'hooks/useCookie';
import CreateSession from 'components/CreateSession';
import Profile from 'components/Profile';
import Lobby from 'components/Lobby';
import Header from 'components/Header';
import Games from 'pages/games/Index';
import Teams from 'pages/teams/Index';
import Plans from 'pages/plans';
import Tests from 'pages/tests/Index';

import 'fontawesome';
import GlobalStyle from 'components/GlobalStyle';
import logo from 'images/logo.png';
import ErrorDialog from 'components/ErrorDialog';
import ContentColumn from 'components/ContentColumn';

const MainSection = styled.section`
  height: 100%;
`;

const Logo = styled.div`
  background-image: url(${logo});
  display: block;
  height: 60px;
  width: 80px;
  background-repeat: no-repeat;
  background-position: center left;
  @media (max-width: 1023px) {
    background-size: cover;
    height: 30px;
    width: 40px;
  }
`;

const Navigation = styled.nav`
  margin-right: auto;
  padding: 1rem 2rem 0;
  @media (max-width: 1023px) {
    padding: 0 2rem;
  }
`;

const StyledLink = styled(Link)`
  transition: color 100ms;
  &:hover {
    text-decoration: none;
    color: var(--primary);
  }
  @media (max-width: 1023px) {
    font-size: 1.5rem;
  }
`;

const Spacer = styled.span`
  padding: 0 1rem;
  font-size: 75%;
  color: var(--grey);
  user-select: none;
  @media (max-width: 1023px) {
    font-size: 1.5rem;
  }
`;

const Photo = styled.img`
  max-height: 32px;
  border-radius: 50%;
  position: relative;
  @media (max-width: 1023px) {
    max-height: 24px;
  }
`;

const TestsLink = () => {
  const [is_developer, set_is_developer] = useCookie('devMode', false);
  const [clicks, set_clicks] = useState(0);
  const [start_time, set_start_time] = useState();

  const handler = useCallback(
    (event) => {
      if (!event.altKey) {
        return;
      }
      if (is_developer) {
        return;
      }

      if (!start_time) {
        set_start_time(event.timeStamp);
      }

      if (event.timeStamp - start_time > 2000) {
        set_start_time(event.timeStamp);
        set_clicks(0);
      } else {
        set_clicks(clicks + 1);
      }

      if (clicks >= 3) {
        set_is_developer(true);
      }
    },
    [clicks, set_clicks, is_developer, set_is_developer, start_time]
  );

  useEffect(() => {
    if (is_developer) {
      return;
    }

    document.addEventListener('click', handler);

    return () => document.removeEventListener('click', handler);
  }, [handler, is_developer]);

  if (!is_developer) {
    return null;
  }

  return (
    <>
      <Spacer>♣</Spacer>
      <StyledLink to="/tests">
        <FontAwesomeIcon icon="ghost" />
      </StyledLink>
    </>
  );
};

const ProfileLink = styled(Link)`
  color: var(--primary);
  text-decoration: none;
  margin: 1rem 2rem;
  transition: color 250ms;
  line-height: 0;
  &:focus {
    outline: 0;
  }
  &:first-child {
    float: right;
  }
  @media (hover: hover) {
    &:hover {
      color: var(--primary-light);
    }
  }
  @media (max-width: 1023px) {
    font-size: 75%;
    margin: 0 2rem;
  }
`;

const remove_loading_indicator = () => {
  const element = document.getElementById('loader');
  if (!element) {
    return;
  }
  element.className = 'hiding';
  window.setTimeout(() => element.parentNode.removeChild(element), 400);
};

const handle_app_startup_messages = (user_state) => {
  const node = document.querySelector('#loader p');

  if (user_state.loading) {
    // We haven't loaded auth yet
    node.innerText = 'Initializing session ...';
    return false;
  } else {
    // We already have all the state we need, we are loaded
    remove_loading_indicator();
    return true;
  }
};

const PrivateRoute = ({ element, ...props }) => {
  const user = useSelector((state) => state.user);
  const logged_in = user && user.uid;

  const finalElement = logged_in ? element : <CreateSession />;

  return <Route {...props} element={finalElement} />;
};

const App = () => {
  const user = useSelector((state) => state.user);
  const logged_in = user && user.uid;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loading && handle_app_startup_messages(user)) {
      setLoading(false);
    }
  }, [loading, user]);

  if (logged_in) {
    LogRocket.identify(user.uid, {
      name: user.name,
      email: user.email,
      plan: 'free',
    });
  }

  return (
    <MainSection>
      <GlobalStyle />

      <Header>
        <Logo />
        <Navigation>
          <StyledLink to="/">Planning poker</StyledLink>
          <Spacer>♠</Spacer>
          <StyledLink to="/games">Games</StyledLink>
          <Spacer>♥</Spacer>
          <StyledLink to="/teams">Teams</StyledLink>
          <TestsLink />
        </Navigation>
        <ProfileLink to="/profile">
          {user && user.photo_url ? (
            <Photo src={user.photo_url} />
          ) : (
            <FontAwesomeIcon icon="user-circle" aria-hidden="true" />
          )}
        </ProfileLink>
      </Header>

      <Routes>
        <Route path="/" element={<Lobby />} />

        <PrivateRoute path="profile" element={<Profile />} />

        <PrivateRoute path="games/*" element={<Games />} />

        <PrivateRoute path="teams/*" element={<Teams />} />

        <Route path="pricing/*" element={<Plans />} />

        <Route path="tests/*" element={<Tests />} />

        <Route
          path="*"
          element={
            <ContentColumn>
              <h1>404 Page not found</h1>
            </ContentColumn>
          }
        />
      </Routes>

      <ErrorDialog />
    </MainSection>
  );
};

export default App;
