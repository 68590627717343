import React, { useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: inline-block;
`;

const Track = styled.div`
  position: relative;
  border: 1px solid var(--reallydarkgrey);
  border-radius: 50px;
  background-color: white;
  padding: 0.3rem 0.3rem 0.2rem 0.3rem;
  display: grid;
  grid-template-columns: repeat(${(props) => props.number_of_children}, 1fr);
  box-shadow: inset 1px 2px 0px #0003;
`;

const Option = styled.label`
  position: relative;
  padding: 0.5rem 1.5rem;
  border-radius: 50px;
  color: ${(props) => (props.selected ? '#fff' : 'var(--primary-dark)')};
  display: inline-block;
  width: 100%;
  text-align: center;
  user-select: none;
  transition: color 100ms ${(props) => !props.selected && '150ms'};
`;

const Selected = styled.div`
  position: absolute;
  border-radius: 50px;
  background-color: var(--primary);
  /*width: ${(props) => props.width}%;*/
  grid-column: 1/-1;
  left: ${(props) => props.offset}%;
  right: ${(props) => 100 - (props.offset + props.width)}%;
  height: 100%;
  grid-row: 1/2;
  box-shadow: inset -1px -2px 0px var(--primary-dark);
  .left_to_right & {
    transition: left 100ms 150ms, right 100ms;
  }
  .right_to_left & {
    transition: right 100ms 150ms, left 100ms;
  }
`;

const Radio = styled.input`
  width: 1px;
  opacity: 0;
`;

export const ToggleOption = ({ name, value, selected, children }) => (
  <Option selected={selected}>
    <Radio type="radio" name={name} value={value} defaultChecked={selected} />
    {children}
  </Option>
);

const ToggleSwitch = ({
  selected_value,
  name = 'toggle_switch',
  onChange,
  children,
}) => {
  const [previously_selected_index, set_previous] = useState(0);
  let selected_index = 0;
  const [direction, set_direction] = useState('left_to_right');

  const change_handler = (event) => {
    onChange(event.target.value);
  };

  const processed_children = React.Children.toArray(children).map(
    (child, index) => {
      if (child.type !== ToggleOption) {
        return child;
      }

      const selected = child.props.value === selected_value;

      if (selected) {
        selected_index = index;
      }

      return React.cloneElement(child, {
        selected,
        name,
      });
    }
  );

  const number_of_children = processed_children.length;

  if (previously_selected_index !== selected_index) {
    set_direction(
      previously_selected_index > selected_index
        ? 'right_to_left'
        : 'left_to_right'
    );
    set_previous(selected_index);
  }

  return (
    <Wrapper className={direction}>
      <Track onChange={change_handler} number_of_children={number_of_children}>
        <Selected
          width={100 / number_of_children}
          offset={(100 / number_of_children) * selected_index}
          direction={direction}
        />
        {processed_children}
      </Track>
    </Wrapper>
  );
};

export default ToggleSwitch;
