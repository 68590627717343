import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Firebase from 'firebase/app';
import marked from 'marked';

import useResizeObserver from 'hooks/useResizeObserver';
import Drawer from 'components/Game/Drawer';
import Deck from 'components/Deck';
import Board from 'components/Game/Board';

const Main = styled.main`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, auto) min-content;
  grid-template-rows: 1fr min-content 1fr;
  grid-template-areas:
    'current_story stories'
    'board stories'
    'deck stories';
  overflow: hidden;
`;

const CurrentStoryText = styled.p`
  max-height: 0px;
  overflow: hidden;
  transition: max-height 250ms ease-out;
`;

const TopRightIcons = styled.span`
  float: right;
  font-size: 1.5rem;
  margin-top: 3rem;
  opacity: 0;
  transition: opacity 250ms ease-in;
`;

const with_description = css`
  background: #fff;
  box-shadow: 1px 3px 0 0 #aaa, 1px 5px 5px rgba(0, 0, 0, 0.25);
  border-bottom-color: #ddd;
  border-top-color: #fff;
  transition: all 250ms;
  ${CurrentStoryText} {
    max-height: 10000px;
    transition: max-height 250ms ease-in 150ms;
  }
  ${TopRightIcons} {
    opacity: 1;
  }
`;

const CurrentStoryContainer = styled.div`
  position: absolute;
  z-index: 5;
  border-radius: 4px;
  padding: 0 2rem;
  grid-area: current_story;
  margin: 10rem 4rem 0;
  transition: all 250ms 150ms;
  padding: 1rem 5rem 3rem 5rem;
  border-bottom: 1px solid transparent;
  border-top: 1px solid transparent;
  width: calc(100% - 8rem);

  @media (max-width: 1023px) {
    margin: 7rem 4rem 0;
  }

  @media (hover: hover) {
    &:hover {
      max-height: calc(100vh - 400px);
      overflow: overlay;
      ${with_description}
    }
  }

  &.open {
    max-height: calc(100vh - 400px);
    overflow: overlay;
    ${with_description}
  }
`;

const CurrentStoryHolder = styled.div`
  position: relative;
  top: 0;
  left: 0;
  height: 20rem;
  @media (max-width: 1023px) {
    height: 16rem;
  }
`;

const CurrentStory = ({ title, children }) => {
  const [open, set_open] = useState(false);
  return (
    <CurrentStoryHolder>
      <CurrentStoryContainer
        className={open ? 'open' : ''}
        onClick={() => set_open(!open)}
      >
        <TopRightIcons title={open ? 'unpin' : 'pin'}>
          {open ? (
            <FontAwesomeIcon icon="thumbtack" aria-hidden="true" />
          ) : (
            <FontAwesomeIcon icon={['far', 'thumbtack']} aria-hidden="true" />
          )}
        </TopRightIcons>
        <h1>{title}</h1>
        {children}
      </CurrentStoryContainer>
    </CurrentStoryHolder>
  );
};

const Game = ({ game, update_game }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const user = useSelector((state) => state.user);
  const { ref, width } = useResizeObserver();

  const scale = Math.min(width ? width / 895 : 1, 1.0);

  const calculate_score = (player_votes, deck) => {
    const card_points = Object.values(player_votes).map(
      (card_index) => game.deck.cards[card_index].points
    );
    const actual_votes = card_points.filter(Boolean);
    const total_points = actual_votes.reduce((i, x) => i + x, 0);
    const score = total_points / actual_votes.length;

    return score;
  };

  const set_current_story = (index) => {
    const update = { current_story: index };

    Object.keys(game.players).map(
      (id) => (update[`players.${id}.card`] = null)
    );

    // When moving to the next story
    if (game.current_story < index) {
      const score = calculate_score(
        game.stories[game.current_story].votes,
        game.deck
      );

      update[`scores.${index - 1}`] = score;
    }

    update_game(update);
  };

  const go_to_summary = () => {
    const last_story_index = Object.keys(game.stories).length - 1;
    const score = calculate_score(
      game.stories[game.current_story].votes,
      game.deck
    );

    update_game({
      current_story: Infinity,
      [`scores.${last_story_index}`]: score,
    });

    navigate(`/games/${id}/summary`);
  };

  if (game.current_story === Infinity) {
    return null;
  }

  const current_vote = game.stories[game.current_story].votes[user.uid];

  const card_selected = (index) => {
    const deselect = current_vote === index;
    const key = `stories.${game.current_story}.votes.${user.uid}`;
    let update;

    if (deselect) {
      update = {
        [key]: Firebase.firestore.FieldValue.delete(),
      };
    } else {
      update = {
        [key]: index,
      };
    }

    update_game(update);
  };

  return (
    <Main>
      <Drawer
        stories={game.stories}
        current={game.current_story}
        set_current_story={set_current_story}
        go_to_summary={go_to_summary}
        admin={game.owner === user.uid}
      />
      {Object.keys(game.stories).length !== 0 && (
        <CurrentStory title={game.stories[game.current_story].title}>
          <CurrentStoryText
            dangerouslySetInnerHTML={{
              __html: marked(game.stories[game.current_story].description),
            }}
          />
        </CurrentStory>
      )}
      <Board game={game} user={user} />
      <Deck
        ref={ref}
        scale={scale}
        deck={game.deck}
        selected={current_vote}
        onSelect={card_selected}
      />
    </Main>
  );
};

export default Game;
