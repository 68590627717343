const initial_state = [];

function error(state = initial_state, action) {
  switch (action.type) {
    case 'ADD_ERROR':
      return [
        ...state,
        { message: action.error.message, location: action.location },
      ];
    case 'REMOVE_ERROR':
      state.splice(action.index, 1);
      return state;
    case 'CLEAR_ERRORS':
      return initial_state;
    default:
      return state;
  }
}

export default error;
