import { createLogicMiddleware } from 'redux-logic';

import user_logic from 'flows/user';

const logic = [...user_logic];

export default (firebase, history) => {
  const dependancies = {
    firebase,
    history,
  };

  return createLogicMiddleware(logic, dependancies);
};
