import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Button, { ButtonGroup } from 'components/FancyButton';
import Field from 'components/Field';
import ContentColumn from 'components/ContentColumn';
import HR from 'components/HR';

import { USER_UPDATE, USER_UNLOAD } from 'flows/user';

const Lobby = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.firebase.auth);
  const user = useSelector((state) => state.user);

  const [name, name_change] = useState(user.name || '');
  const unsaved_changes = user.name !== name;

  return (
    <ContentColumn>
      <h1>Welcome</h1>
      <h2>Your settings</h2>
      <Field
        placeholder="Enter your name"
        onChange={name_change}
        value={name}
        label="Enter your name so that your team mates know who is voting."
      />
      <ButtonGroup>
        <Button
          disabled={!unsaved_changes}
          onClick={() => dispatch({ type: USER_UPDATE, name: name })}
        >
          Save changes
        </Button>
      </ButtonGroup>
      <HR />
      <h2>Log out</h2>
      <p>Hope to see you soon again!</p>
      <ButtonGroup>
        <Button
          onClick={() => {
            auth.signOut();
            dispatch({ type: USER_UNLOAD });
          }}
        >
          Logout
        </Button>
      </ButtonGroup>
    </ContentColumn>
  );
};

export default Lobby;
