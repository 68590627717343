import styled from 'styled-components';

const ContentColumn = styled.section`
  width: auto;
  max-width: 960px;
  margin: 0 auto;
  padding: 10rem 0rem 5rem 0rem;
  @media (max-width: 1023px) {
    padding: 5rem 2rem 5rem 2rem;
  }
`;

export default ContentColumn;
