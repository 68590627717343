import { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

function useDocument(
  collection,
  id,
  on_error = (x) => x,
  conditions = (x) => x
) {
  const db = useSelector((state) => state.firebase.db);
  const [doc_ref] = useState(
    id ? conditions(db.collection(collection).doc(id)) : undefined
  );
  const [loading, set_loading] = useState(true);
  const [document, set_document] = useState();
  const [error, set_error] = useState();

  useEffect(() => {
    if (!id || !doc_ref) {
      return;
    }
    const unsubscribe = doc_ref.onSnapshot(
      (doc) => {
        if (doc.exists) {
          set_document(doc.data());
        } else {
          set_error('Requested document was not found', id);
        }
      },
      (error) => set_error(error)
    );
    return unsubscribe;
  }, [id, doc_ref]);

  useEffect(() => {
    if (document) {
      set_loading(false);
    }
  }, [document]);

  useEffect(() => {
    if (error) {
      on_error(error);
    }
  }, [error, on_error]);

  const update = useCallback(
    (data) => {
      if (!doc_ref) {
        console.log('Update before doc ref is available...');
        return;
      }
      doc_ref.update(data).catch((error) => set_error(error));
    },
    [doc_ref, set_error]
  );

  const create = useCallback(
    (document, conditions = (x) => x) => {
      if (document.id) {
        return conditions(db.collection(collection).doc(document.id))
          .set(document)
          .catch((error) => set_error(error));
      } else {
        return conditions(db.collection(collection))
          .add(document)
          .catch((error) => set_error(error));
      }
    },
    [db, set_error, collection]
  );

  const remove = useCallback(() => {
    doc_ref.delete().catch((error) => set_error(error));
  }, [doc_ref, set_error]);

  return { loading, document, create, update, remove };
}

export default useDocument;
