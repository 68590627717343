import { useDispatch } from 'react-redux';

import useCollection from 'hooks/useCollection';

function useDecks(conditions = (x) => x) {
  const dispatch = useDispatch();

  const on_error = (error) => {
    console.error('hooks/useDecks.js', error);
    dispatch({
      type: 'ADD_ERROR',
      location: 'hooks/useDecks.js',
      error,
    });
  };

  return useCollection('decks', on_error, conditions);
}

export default useDecks;
