import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import marked from 'marked';

import GameAdmin from './GameAdmin';

const Holder = styled.div`
  height: 100%;
  grid-area: stories;
  background-color: #fff;
  box-shadow: inset 5px 0 4px -3px #ddd;
  max-width: 38rem;
`;

const Drawer = styled.div`
  overflow: hidden;
  width: ${(props) => (props.open ? '38rem' : '0rem')};
  height: 100%;
  display: grid;
  grid-template-rows: auto min-content;
  @media (max-width: 1023px) {
    width: ${(props) => (props.open ? '30rem' : '0rem')};
  }
  transition: width 250ms, padding 250ms;
`;

const List = styled.aside`
  padding: 12rem 4rem;
  width: 38rem;
  @media (max-width: 1023px) {
    padding: 8rem 4rem 0;
    width: 30rem;
  }
`;

const ToggleButton = styled.div`
  position: absolute;
  right: 0.5rem;
  top: 50%;
  background-color: #fff8;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  color: var(--grey);
  cursor: pointer;
  transition: background-color 250ms, color 250ms;

  &:hover {
    background-color: var(--offwhite);
    color: var(--darkgrey);
  }

  &:active {
    background-color: #fff;
    color: #000;
  }
`;

const Next = styled.span`
  display: inline-block;
  font-size: 1.25rem;
`;

const Title = styled.h2`
  font-size: 2rem;
  font-family: 'Sorts Mill Goudy', serif;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  margin: 0.83em 0;
  position: relative;
`;

const Description = styled.p`
  overflow: hidden;
  margin: 0;
  border-bottom: 1px solid var(--grey);
  padding-bottom: 2rem;
  padding-top: 1rem;
  position: relative;
  max-height: 30vh;
  white-space: pre-line;

  line-height: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > * {
    transform: scale(0.75);
    margin: 0;
    width: calc(150% - 5rem);
  }

  &::after{
    content: "${(props) => `${props.current}/${props.max}`}";
    position: absolute;
    right: 0;
    bottom: 0;
    font-size: 1.25rem;
    font-size: 1.25rem;
  }
`;

const Progress = styled.div.attrs((props) => {
  const calculated_ratio = props.max / props.current;
  const ratio = isNaN(calculated_ratio) ? 1 : calculated_ratio;
  return {
    percent: Math.ceil(100 / ratio),
  };
})`
  position: relative;
  margin-bottom: 4rem;
  background-color: var(--grey);
  height: 5px;
  width: 100%;
  &::before {
    content: "";
    width: ${(props) => `${props.percent}%`};
    height: 5px;
    background-color: var(--primary-light);
    display: block;
  }
  &::after {
    content: "${(props) => `${props.percent}`}%";
    background-color: white;
    border: 3px solid var(--primary-light);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -21px;
    left: ${(props) =>
      `calc( ${props.percent}% - ( 46px * ( ${props.percent} / 100 )))`};
    font-family: "Sorts Mill Goudy",serif;
    font-size: 1.5rem;
  }
`;

const Stories = ({
  stories,
  current,
  set_current_story,
  go_to_summary,
  admin,
}) => {
  const [open, set_open] = useState(true);
  const number_of_stories = Object.keys(stories).length;
  const current_story = stories[current];
  const next_story = stories[current + 1];

  if (!admin) {
    return <Holder />;
  }

  return (
    <Holder>
      <Drawer open={open}>
        <List>
          {current >= 0 && current < number_of_stories && (
            <>
              <Progress current={current} max={number_of_stories - 1} />
              <Title>{current_story.title} </Title>
              <Description
                dangerouslySetInnerHTML={{
                  __html: marked(current_story.description),
                }}
                current={current + 1}
                max={number_of_stories}
              />
              {next_story && (
                <>
                  <Next>Next</Next>
                  <Title next>{next_story.title} </Title>
                </>
              )}
            </>
          )}
        </List>
        <GameAdmin
          {...{
            current_story: current,
            set_current_story,
            go_to_summary,
            max_story: number_of_stories - 1,
          }}
        />
      </Drawer>
      <ToggleButton
        onClick={() => {
          set_open(!open);
        }}
      >
        <span className="fa-layers fa-fw fa-2x">
          <FontAwesomeIcon icon="circle" />
          {open ? (
            <FontAwesomeIcon
              inverse
              icon="chevron-right"
              aria-hidden="true"
              transform="shrink-2 right-1"
            />
          ) : (
            <FontAwesomeIcon
              inverse
              icon="chevron-left"
              aria-hidden="true"
              transform="shrink-2 left-1"
            />
          )}
        </span>
      </ToggleButton>
    </Holder>
  );
};

export default Stories;
