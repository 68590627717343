import React, { useState } from 'react';
//import styled from "styled-components";
import parse_csv from 'csv-parse/lib/sync';

import Button, { ButtonGroup } from 'components/FancyButton';
import Field from 'components/Field';
import Dialog from 'components/Dialog';

const File = ({ file }) => {
  const { name, type = 'n/a', size, lastModifiedDate } = file;

  return (
    <p>
      <strong>{name}</strong>({type}) - {size}, bytes, last modified:&nbsp;
      {lastModifiedDate ? lastModifiedDate.toLocaleDateString() : 'n/a'}
    </p>
  );
};

/*
const IssueList = styled.section`
  max-height: 30vh;
  overflow-y: scroll;
`;

const Issue = ({ id, status, title, description, reporter, link }) => (
  <div>
    <h3>
      <a href={id}>{title}</a>
    </h3>
    {description && <p>{description}</p>}
  </div>
);
*/

export default ({ onClose }) => {
  const [file, set_file] = useState();
  const [issues, set_issues] = useState([]);
  const reader = new FileReader();

  const csv = ({ target: { result } }) => {
    const records = parse_csv(result, {
      columns: true,
      skip_empty_lines: true,
    });

    // Identify provider
    if (
      records[0]['Issue Type'] !== undefined &&
      records[0]['Resolution'] !== undefined
    ) {
      // JIRA
      const issues = records.map((record) => ({
        id: record['Issue key'],
        status: record['Status'],
        title: record['Summary'],
        reporter: record['Reporter'],
        description: record['Description'],
      }));

      set_issues(issues);
    } else {
      console.error(`NOT IMPLEMENTED: Unhandled CSV provider`, records[0]);
    }
  };

  const json = ({ target: { result } }) => {
    const records = JSON.parse(result);

    // Identify provider
    if (records.idOrganization && records.powerUps) {
      // Trello
      const cards = records.cards.filter((card) => card.closed);
      const statuses = {};
      const members = {};

      // Create status lookup table
      records.lists.forEach((list) => (statuses[list.id] = list.name));
      // Create member lookup table
      records.members.forEach((member) => (members[member.id] = member));

      const issues = cards.map((card) => {
        return {
          id: card.id,
          status: statuses[card.idList], //idList
          title: card.name,
          reporter: card.idMembers[0]
            ? members[card.idMembers[0]].fullName ||
              members[card.idMembers[0]].username
            : null,
          description: card.desc,
          link: card.shortUrl,
        };
      });

      set_issues(issues);
    } else {
      console.error(`NOT IMPLEMENTED: Unhandled JSON provider`, records);
    }
  };

  const on_load = (files) => {
    const file = files[0];

    set_file(file);

    switch (file.type) {
      case 'text/csv':
        reader.onload = csv;
        reader.readAsText(file);
        break;
      case 'application/json':
        reader.onload = json;
        reader.readAsText(file);
        break;
      default:
        console.error(
          `NOT IMPLEMENTED: Unhandled filetype "${file.type}"`,
          file
        );
    }
  };

  return (
    <Dialog onClose={() => onClose()}>
      <h1>Import</h1>
      <p>
        You can import CSV exports from JIRA (both the current fields and full
        version) as well as JSON from Trello, for now.
      </p>
      <Field
        label="Select a file to import"
        placeholder="Password"
        type="file"
        onChange={on_load}
      />
      {file && <File key={file.name} file={file} />}
      {/* <IssueList>
        {issues.map(issue => (
          <Issue key={issue.id} {...issue} />
        ))}
      </IssueList> */}
      <ButtonGroup>
        <Button onClick={() => onClose()}>Cancel</Button>
        <Button disabled={issues.length === 0} onClick={() => onClose(issues)}>
          Import
        </Button>
      </ButtonGroup>
    </Dialog>
  );
};
