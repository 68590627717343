import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Routes, Route, Outlet, Link } from 'react-router-dom';

import ContentColumn from 'components/ContentColumn';
import SectionHeader from 'components/SectionHeader';

import CardSize from './CardSize';
import DeckSize from './DeckSize';
import Errors from './Errors';

const TestHeader = styled.header`
  position: relative;
  top: 8rem;
  background-color: #d3e8e8;
  border-bottom: 1px solid var(--secondary);
`;

const Nav = styled.nav`
  padding: 2rem 0;
  width: auto;
  max-width: 959px;
  margin: 0 auto;
  & a,
  & a:link {
    color: var(--secondary);
    margin-right: 1rem;
    font-size: 1.6rem;
  }
  & a:hover,
  & a:visited,
  & a:active {
    color: var(--secondary);
  }
`;

export const Home = () => (
  <ContentColumn>
    <SectionHeader tooltip="Test pages to easily check different components across devices">
      Tests
    </SectionHeader>
    <p>
      These are component or flow test pages to easily test things like error
      states and loading indicators without having to get into an actual
      situation where the error occurs.
    </p>
  </ContentColumn>
);

const Tests = () => (
  <>
    <TestHeader>
      <Nav>
        <Link to="">
          <FontAwesomeIcon icon="home" />
        </Link>
        <Link to="card_size">Card</Link>
        <Link to="deck_size">Deck</Link>
        <Link to="errors">Errors</Link>
      </Nav>
    </TestHeader>

    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="card_size" element={<CardSize />} />
      <Route path="deck_size" element={<DeckSize />} />
      <Route path="errors" element={<Errors />} />
    </Routes>

    <Outlet />
  </>
);

export default Tests;
