import { useDispatch } from 'react-redux';

import useCollection from 'hooks/useCollection';

function useConnections(conditions = (x) => x) {
  const dispatch = useDispatch();

  const on_error = (error) => {
    console.error('hooks/useConnections.js', error);
    dispatch({
      type: 'ADD_ERROR',
      location: 'hooks/useConnections.js',
      error,
    });
  };

  return useCollection('connections', on_error, conditions);
}

export default useConnections;
