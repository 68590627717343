import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import firebase from 'firebase/app';
import 'firebase/auth';

import { USER_SET_NAME } from 'flows/user';

import ContentColumn from 'components/ContentColumn';
import Field from 'components/Field';
import Button from 'components/FancyButton';

const CreateSession = () => {
  const dispatch = useDispatch();
  const [name, set_name] = useState('');
  const [disabled, set_disabled] = useState(true);
  const auth = useSelector((state) => state.firebase.auth);

  auth.useDeviceLanguage();

  const google = new firebase.auth.GoogleAuthProvider();
  google.addScope('https://www.googleapis.com/auth/userinfo.profile');
  google.addScope('https://www.googleapis.com/auth/userinfo.email');

  const login_anonymously = () => {
    set_disabled(true);
    auth
      .signInAnonymously()
      .then(() => {
        dispatch({ type: USER_SET_NAME, name });
      })
      .catch((error) => {
        dispatch({ type: 'ADD_ERROR', location: 'CreateSession', error });
      });
  };

  const login_with_google = () => {
    firebase.auth().signInWithRedirect(google);
  };

  return (
    <ContentColumn>
      <Field
        placeholder="Enter your name"
        onChange={(name) => {
          set_name(name);
          set_disabled(!name);
        }}
        value={name}
        label="Enter your name so that your team mates know who is voting."
      />
      <Button onClick={login_anonymously} disabled={disabled}>
        Continue anonymously
      </Button>

      <Button onClick={login_with_google}>
        <FontAwesomeIcon icon={['fab', 'google']} />
        Login with Google
      </Button>
    </ContentColumn>
  );
};

export default CreateSession;
