import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, summary {
display : block;
}
audio, canvas, video {
display : inline-block;
}
audio:not([controls]) {
display : none;
height : 0;
}
[hidden] {
display : none;
}
a:focus {
outline: none;
}
a:active, a:hover {
outline : 0;
}
h1 {
font-size : 2em;
}
abbr[title] {
border-bottom : 1px dotted;
}
b, strong {
font-weight : 700;
}
dfn {
font-style : italic;
}
mark {
background : #ff0;
color : #000;
}
code, kbd, pre, samp {
font-family : monospace, serif;
font-size : 1em;
}
pre {
white-space : pre-wrap;
overflow-wrap : break-word;
}
small {
font-size : 80%;
}
sub, sup {
font-size : 75%;
line-height : 0;
position : relative;
vertical-align : baseline;
}
sup {
top : -0.5em;
}
sub {
bottom : -0.25em;
}
img {
border : 0;
}
svg:not(:root) {
overflow : hidden;
}
fieldset {
border : silver solid 1px;
margin : 0 2px;
padding : 0.35em 0.625em 0.75em;
}
button, input, select, textarea {
font-family : inherit;
font-size : 100%;
margin : 0;
}
button, input {
line-height : normal;
}
button, html input[type="button"], input[type="reset"], input[type="submit"] {
cursor : pointer;
}
button[disabled], input[disabled] {
cursor : default;
}
input[type="checkbox"], input[type="radio"] {
box-sizing : border-box;
padding : 0;
}
input[type="search"] {
box-sizing : content-box;
}
textarea {
overflow : auto;
vertical-align : top;
}
table {
border-collapse : collapse;
border-spacing : 0;
}
figure {
  margin : 0;
}
legend, button::-moz-focus-inner, input::-moz-focus-inner {
border : 0;
padding : 0;
}
.clearfix:after {
visibility : hidden;
display : block;
font-size : 0;
content : " ";
clear : both;
height : 0;
}
* {
box-sizing : border-box;
}

  img {
    max-width: 100%;
  }

  :root {
    --primary: #8839B8;
    --primary-light: #bb6cea;
    --primary-dark: #562375;
    --secondary: #558888;
    --secondary-light: #5cbbbb;
    --secondary-dark: #365D5D;
    --offwhite: #fafafa;
    --lightgrey: #EDEEED;
    --grey: #d4d6d4;
    --darkgrey: #a5a5a5;
    --reallydarkgrey: #636363;
    --darkestgrey: #888;
    --red: #fb5d59;
    --darkred: #a72b22;
    --redshadow: #581b16;

    --chip-color: var(--secondary-light);
    --danger-notice: #9c4242;
    --warning-notice: #daa23a;
    --ok-notice: #596;
  }

  html {
    font-family: 'Open Sans', sans-serif;
    font-size: 10px;
  }

  body {
    background: var(--offwhite);
    margin : 0;
  }

  #root {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  #loader {
    transition: opacity 350ms;
    &.hiding {
      opacity:0;
    }
  }

  p {
    font-size: 1.6rem;
    line-height: 1.5;
  }

  h1, h2 {
    font-family: 'Sorts Mill Goudy', serif;
  }

  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 2.0rem;
  }

  h3 {
    font-family: 'Open Sans',serif;
    font-size: 1.6rem;
    font-weight: 600;
  }

  a, a:link {
    color: var(--primary);
    text-decoration: none;
  }

  a:hover, a:visited, a:active {
    color: var(--primary-light);
    text-decoration: underline;
  }

  /**
 * Tooltip Styles
 */

/* Base styles for the element that has a tooltip */
[data-tooltip],
.tooltip {
  position: relative;
  cursor: pointer;
}

/* Base styles for the entire tooltip */
[data-tooltip]:before,
[data-tooltip]:after,
.tooltip:before,
.tooltip:after {
  position: absolute;
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition:
      opacity 0.2s ease-in-out,
        visibility 0.2s ease-in-out,
        -webkit-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
    -moz-transition:
        opacity 0.2s ease-in-out,
        visibility 0.2s ease-in-out,
        -moz-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
    transition:
        opacity 0.2s ease-in-out,
        visibility 0.2s ease-in-out,
        transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform:    translate3d(0, 0, 0);
  transform:         translate3d(0, 0, 0);
  pointer-events: none;
}

/* Show the entire tooltip on hover and focus */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
[data-tooltip]:focus:before,
[data-tooltip]:focus:after,
.tooltip:hover:before,
.tooltip:hover:after,
.tooltip:focus:before,
.tooltip:focus:after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

/* Base styles for the tooltip's directional arrow */
.tooltip:before,
[data-tooltip]:before {
  z-index: 1001;
  border: 6px solid transparent;
  background: transparent;
  content: "";
}

/* Base styles for the tooltip's content area */
.tooltip:after,
[data-tooltip]:after {
  z-index: 1000;
  padding: 8px;
  width: 160px;
  background-color: #000;
  background-color: hsla(0, 0%, 20%, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  font-size: 14px;
  line-height: 1.2;
}

/* Directions */

/* Top (default) */
[data-tooltip]:before,
[data-tooltip]:after,
.tooltip:before,
.tooltip:after,
.tooltip--top:before,
.tooltip--top:after {
  bottom: 100%;
  left: 50%;
}

[data-tooltip]:before,
.tooltip:before,
.tooltip--top:before {
  margin-left: -6px;
  margin-bottom: -12px;
  border-top-color: #000;
  border-top-color: hsla(0, 0%, 20%, 0.9);
}

/* Horizontally align top/bottom tooltips */
[data-tooltip]:after,
.tooltip:after,
.tooltip--top:after {
  margin-left: -80px;
}

[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
[data-tooltip]:focus:before,
[data-tooltip]:focus:after,
.tooltip:hover:before,
.tooltip:hover:after,
.tooltip:focus:before,
.tooltip:focus:after,
.tooltip--top:hover:before,
.tooltip--top:hover:after,
.tooltip--top:focus:before,
.tooltip--top:focus:after {
  -webkit-transform: translateY(-12px);
  -moz-transform:    translateY(-12px);
  transform:         translateY(-12px);
}

/* Left */
.tooltip--left:before,
.tooltip--left:after {
  right: 100%;
  bottom: 50%;
  left: auto;
}

.tooltip--left:before {
  margin-left: 0;
  margin-right: -12px;
  margin-bottom: 0;
  border-top-color: transparent;
  border-left-color: #000;
  border-left-color: hsla(0, 0%, 20%, 0.9);
}

.tooltip--left:hover:before,
.tooltip--left:hover:after,
.tooltip--left:focus:before,
.tooltip--left:focus:after {
  -webkit-transform: translateX(-12px);
  -moz-transform:    translateX(-12px);
  transform:         translateX(-12px);
}

/* Bottom */
.tooltip--bottom:before,
.tooltip--bottom:after {
  top: 100%;
  bottom: auto;
  left: 50%;
}

.tooltip--bottom:before {
  margin-top: -12px;
  margin-bottom: 0;
  border-top-color: transparent;
  border-bottom-color: #000;
  border-bottom-color: hsla(0, 0%, 20%, 0.9);
}

.tooltip--bottom:hover:before,
.tooltip--bottom:hover:after,
.tooltip--bottom:focus:before,
.tooltip--bottom:focus:after {
  -webkit-transform: translateY(12px);
  -moz-transform:    translateY(12px);
  transform:         translateY(12px);
}

/* Right */
.tooltip--right:before,
.tooltip--right:after {
  bottom: 50%;
  left: 100%;
}

.tooltip--right:before {
  margin-bottom: 0;
  margin-left: -12px;
  border-top-color: transparent;
  border-right-color: #000;
  border-right-color: hsla(0, 0%, 20%, 0.9);
}

.tooltip--right:hover:before,
.tooltip--right:hover:after,
.tooltip--right:focus:before,
.tooltip--right:focus:after {
  -webkit-transform: translateX(12px);
  -moz-transform:    translateX(12px);
  transform:         translateX(12px);
}

/* Move directional arrows down a bit for left/right tooltips */
.tooltip--left:before,
.tooltip--right:before {
  top: 3px;
}

/* Vertically center tooltip content for left/right tooltips */
.tooltip--left:after,
.tooltip--right:after {
  margin-left: 0;
  margin-bottom: -16px;
}
`;
