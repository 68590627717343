import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

import { BrowserRouter as Router } from 'react-router-dom';
import App from './components/App';
import { Provider } from 'react-redux';

import configureStore from 'store';

const store = configureStore(/* provide initial state if any */);

LogRocket.init('ty5gmi/planningpoker');
setupLogRocketReact(LogRocket);

if (process.env.NODE_ENV === 'development') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    trackAllPureComponents: true,
  });
}

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
);

// Scroll down by one pixel to hide the address bar on mobile devices
window.addEventListener(
  'load',
  function (e) {
    setTimeout(function () {
      window.scrollTo(0, 1);
    }, 1);
  },
  false
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
