import React from 'react';
import styled, { css } from 'styled-components';

const main_group = css`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 2rem 1rem 2rem;
  background: var(--lightgrey);
  box-shadow: inset 1px 3px 0 0 #ccc, inset 0 0 100px 0 rgba(0, 0, 0, 0.25),
    inset 0 4px 0 #aaa, inset 1px 5px 5px rgba(0, 0, 0, 0.5);
  border-top: 1px solid #ddd;
`;

const Group = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${(props) => props.main && main_group}
`;

const SubGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) =>
    props.left ? 'flex-start' : props.center ? 'center' : 'flex-end'};
  flex: 1;
  & > button:first-child:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  & > button:not(:first-child):not(:last-child) {
    border-radius: 0;
  }

  & > button:last-child:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export const ButtonGroup = ({ children, ...props }) => {
  const left = React.Children.toArray(children).filter(
    (child) => child.props.left
  );
  const center = React.Children.toArray(children).filter(
    (child) => child.props.center
  );
  const right = React.Children.toArray(children).filter(
    (child) => !child.props.left && !child.props.center
  );

  return (
    <Group {...props}>
      {left.length > 0 && <SubGroup left>{left}</SubGroup>}
      {center.length > 0 && <SubGroup center>{center}</SubGroup>}
      {right.length > 0 && <SubGroup right>{right}</SubGroup>}
    </Group>
  );
};

const Button = styled.button`
  background-color: var(--primary-light);
  font-size: 12px;
  text-decoration: none;
  color: var(--offwhite);
  position: relative;
  padding: 10px 20px;
  border-left: solid 1px var(--primary);
  margin: 2rem 0 1rem ${(props) => (props.label ? '35px' : '0')};
  background-image: linear-gradient(
    to top,
    var(--primary) 0%,
    var(--primary-light) 100%
  );

  border-radius: ${(props) => (props.label ? '0 5px 5px 0' : '5px')};
  border: none;
  box-shadow: inset 0px 1px 0px var(--primary),
    0px 3px 0px 0px var(--primary-dark), 1px 3px 4px #555;
  transition: box-shadow 250ms, color 250ms, bottom 250ms;
  bottom: 0;
  &[disabled] {
    background-color: ${(props) => props.color || 'var(--grey)'};
    border-left: solid 1px #0001;
    background-image: linear-gradient(to top, #0008 0%, transparent 100%);
    background-blend-mode: soft-light;

    box-shadow: inset -1px 1px 0px #0003, 0px 1px 0px #0005,
      0px 1px 0px ${(props) => props.color || 'white'}, 0px 3px 0px 0px #000a,
      0px 3px 0px 0px ${(props) => props.color || 'white'}, 1px 3px 4px #555;
    color: var(--offwhite);
    pointer-events: none;
    &::before {
      background-color: var(--darkgrey);
      background-image: linear-gradient(
        to top,
        var(--darkestgrey) 0%,
        var(--darkgrey) 100%
      );
      box-shadow: inset 0px 1px 0px var(--darkestgrey),
        0px 3px 0px 0px var(--reallydarkgrey), -1px 3px 4px #555;
      color: var(--grey);
      text-shadow: 1px 1px 0px #333;
      border-right: solid 1px var(--darkestgrey);
    }
  }
  &:hover {
    box-shadow: inset 0px 1px 0px var(--primary),
      0px 3px 0px 0px var(--primary-dark), 1px 5px 5px #555;
    color: #fff;
    bottom: 3px;
    &::before {
      box-shadow: inset 0px 1px 0px var(--primary-dark), 0px 3px 0px 0px #333,
        -1px 5px 5px #555;
    }
  }
  &:focus {
    outline: var(--primary-light) auto 5px;
  }
  &:focus:hover {
    outline: var(--primary-light) auto 5px;
  }

  &::before {
    transition: box-shadow 250ms, color 250ms;
    display: ${(props) => (props.label ? 'block' : 'none')};
    background-color: var(--primary);
    content: attr(label);
    width: 35px;
    max-height: 29px;
    height: 100%;
    position: absolute;
    padding-bottom: 8px;
    top: 0px;
    left: -36px;
    font-size: 2.5rem;
    font-weight: bold;
    font-family: 'Sorts Mill Goudy', serif;
    color: var(--lightgrey);
    text-shadow: 1px 1px 0px var(--primary-dark);
    border-right: solid 1px var(--primary-dark);
    background-image: linear-gradient(
      to top,
      var(--primary-dark) 0%,
      var(--primary) 100%
    );
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    box-shadow: inset 0px 1px 0px var(--primary-dark), 0px 3px 0px 0px #333,
      -1px 3px 4px #555;
  }
`;

export const SmallButton = styled(Button)`
  font-size: 1rem;
  margin: 0;
  padding: 3px 10px;
  border-radius: 3px;
  box-shadow: inset 0px 1px 0px var(--primary),
    0px 2px 0px 0px var(--primary-dark), 1px 3px 4px #555;
  &:hover {
    bottom: 1px;
    box-shadow: inset 0px 1px 0px var(--primary),
      0px 2px 0px 0px var(--primary-dark), 1px 4px 4px #555;
  }
`;

export default Button;
