import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Chip from 'components/Chip';
import ContentColumn from 'components/ContentColumn';
import Button, { ButtonGroup } from 'components/FancyButton';

const CardBox = styled.span`
  font-family: 'Merriweather Sans';
  color: white;
  background-color: ${(props) => props.color};
  border-radius: 3px;
  box-shadow: -0.1em 0 0.1em var(--grey), 0 0 0 0.1em var(--grey),
    0.1em 0.15em 0.25em #5558;
  background-image: linear-gradient(-10deg, #0003, transparent, #fff3);
  width: 3rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const TH = styled.th`
  text-align: right;
  font-size: 1.2rem;
  vertical-align: baseline;
  padding: 1rem;
  border-right: 1px solid #ddd;
  &:last-child {
    border: none;
  }
`;

const RotatedTH = styled(TH)`
  text-align: center;
`;

const TD = styled.td`
  text-align: right;
  font-size: 1.2rem;
  padding: 1rem;
  border-right: 1px solid #ddd;
  &:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    padding-left: 2rem;
  }
  &:last-child {
    border: none;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    padding-right: 2rem;
  }
  tr:nth-child(odd) & {
    background: #ddd;
    border-right-color: #ccc;
  }
`;

const Player = styled.span`
  writing-mode: vertical-lr;
  text-orientation: sideways;
`;

const Vote = ({ player, card }) => {
  let symbol;

  if (card.value.startsWith('http')) {
    // Image link
    symbol = (
      <>
        &nbsp;
        <img src={card.value} alt="" />
      </>
    );
  } else if (card.value.startsWith('fa-')) {
    // Fontawesome
    symbol = <i className={`fas ${card.value}`}></i>;
  } else {
    // Just text
    symbol = card.value;
  }

  return <CardBox {...card}>{symbol}</CardBox>;
};

const Summary = ({ game, update_game }) => {
  const user = useSelector((state) => state.user);
  const admin = user.uid === game.owner;

  if (!game) {
    return null;
  }

  return (
    <ContentColumn>
      <h1>
        {game.name}
        <Chip ok>Done</Chip>
      </h1>

      <h2>Votes</h2>
      <table>
        <thead>
          <tr>
            <TH>Story</TH>
            {game.active_players().map((uid) => (
              <RotatedTH key={uid}>
                <Player>{game.players[uid].name}</Player>
              </RotatedTH>
            ))}
            <TH>Points</TH>
          </tr>
        </thead>
        <tbody>
          {Object.values(game.stories).map((story, index) => (
            <tr key={index}>
              <TD>{story.title}</TD>
              {game.active_players().map((uid) => (
                <TD key={uid}>
                  <Vote
                    key={uid}
                    player={game.players[uid]}
                    card={game.deck.cards[story.votes[uid]]}
                  />
                </TD>
              ))}
              <TD>{isNaN(game.scores[index]) ? 'n/a' : game.scores[index]}</TD>
            </tr>
          ))}
        </tbody>
      </table>
      {admin && (
        <ButtonGroup main>
          <Button right disabled>
            Export <FontAwesomeIcon icon="cloud-download" />
          </Button>
        </ButtonGroup>
      )}
    </ContentColumn>
  );
};

export default Summary;
