import { combineReducers } from 'redux-immer';
import produce from 'immer';

import user from 'reducers/user';
import errors from 'reducers/errors';

const null_reducer = (state) => state;

export default (history) =>
  combineReducers(produce, {
    firebase: null_reducer,
    user,
    errors,
  });
