import React from 'react';
import styled from 'styled-components';

import { Card } from 'components/Deck';

const BoardSection = styled.section`
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: board;
`;

const Vote = styled.div`
  width: 150px;
  margin: 0 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PlayerName = styled.span`
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  pointer-events: all;
  display: inline-block;
  width: 100%;
  text-align: center;
  font-size: 2rem;
`;

const OtherPlayersCard = ({ showing, card, name }) => (
  <Vote>
    {card ? <Card {...card} reversed={!showing} /> : <Card waiting />}
    <PlayerName title={name}>{name}</PlayerName>
  </Vote>
);

const Board = ({ game, user }) => {
  const votes = game.stories[game.current_story].votes;
  const showing =
    Object.values(game.players).filter((player) => player.present).length ===
    Object.keys(votes).length;

  return (
    <BoardSection>
      {Object.entries(game.players).map(([uid, player]) => {
        if (uid === user.uid || !player.present) {
          return null;
        }

        return (
          <OtherPlayersCard
            key={uid}
            showing={showing}
            card={game.deck.cards[votes[uid]]}
            name={player.name}
          />
        );
      })}
    </BoardSection>
  );
};

export default Board;
