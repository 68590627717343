import { useEffect, useState } from 'react';

function useDelay(timeout, callback, label) {
  const [triggered, set_triggered] = useState(false);

  useEffect(() => {
    const timeout_ref = setTimeout(() => {
      if (callback) {
        callback();
      }
      set_triggered(true);
    }, timeout);
    return () => {
      clearTimeout(timeout_ref);
    };
  }, [timeout, callback]);

  return triggered;
}

export default useDelay;
