import React from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import icon from 'images/icon.png';

const placeholder = css`
  border: 0.3em dashed var(--secondary);
  background-color: var(--secondary-light);
  box-shadow: -0.1em 0 0.1em var(--grey), 0.1em 0.3em 0.5em #555;
  opacity: 0.25;
`;

const backside = css`
  border: 0.5em solid white;
  background-color: var(--secondary);
  background-image: url(${icon}),
    linear-gradient(45deg, #5cbbbb 25%, transparent 25%),
    linear-gradient(-45deg, #5cbbbb 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #5cbbbb 75%),
    linear-gradient(-45deg, transparent 75%, #5cbbbb 75%);

  background-repeat: no-repeat, repeat, repeat, repeat, repeat;
  background-position: center center;
  background-size: contain, 2em 2em, 2em 2em, 2em 2em, 2em 2em;
`;

const CardDiv = styled.div`
  user-select: none;
  position: relative;
  flex-shrink: 0;
  height: auto;
  width: 11em;
  padding-bottom: 6em;
  margin: 0.25em;
  border: 0.5em solid #fff;
  border-radius: 1em;
  box-shadow: -0.1em 0 0.1em var(--grey), 0 0.2em 0 0 var(--grey),
    0.1em 0.3em 0.5em #555;
  transition: box-shadow 250ms;
  background-color: ${(props) => props.color};
  background-image: linear-gradient(-10deg, #0003, transparent, #fff3);
  ${(props) => (props.waiting ? placeholder : null)} ${(props) =>
    props.reversed ? backside : null};
`;

const CardHolder = styled.div`
  display: flex;
  justify-content: center;
  overflow: visible;
  padding-bottom: ${({ selected }) => (selected ? '4em' : '0')};
  transition: padding 150ms, transform 150ms, left 150ms;
  min-width: 0;
  z-index: ${({ selected }) => (selected ? '2' : 'unset')};
  @media (max-width: 1023px) {
    transform: rotate(3deg) scale(1);
  }
  @media (hover: hover) {
    &:hover {
      padding-bottom: ${({ selected }) => (selected ? '2.5em' : '1em')};
      transform: rotate(-0deg) scale(1.1);
      transition: padding 150ms ease-in-out 150ms,
        transform 150ms ease-in-out 150ms, left 150ms ease-in-out 150ms;
      z-index: 1;
      ${CardDiv} {
        box-shadow: -0.1em 0 0.1em var(--grey), 0 0.2em 0 0 var(--grey),
          0.1em 0.3em 0.8em #555;
      }
      @media (max-width: 500px) {
        padding-bottom: 4em;
        transform: rotate(-10deg) scale(1.1);
      }
    }
  }
`;

const span = css`
  font-family: 'Merriweather Sans';
  color: white;
`;

const CenterSymbol = styled.span`
  ${span}
  display: inline-block;
  position: relative;
  width: 100%;
  font-size: 4em;
  line-height: 1em;
  text-align: center;
  padding-top: 1.5em;
  & > img {
    max-height: 4rem;
    position: absolute;
    left: calc(50% - 2rem);
    top: 3.5rem;
  }
`;
const TopCornerSymbol = styled.span`
  ${span}
  position: absolute;
  top: 0.8em;
  left: 1em;
  font-size: 1.5em;
  line-height: 1em;
  width: 1.4em;
  text-align: center;
  & > img {
    max-height: 2.5rem;
    position: absolute;
    left: 0;
    top: 0;
  }
`;

const Card = ({
  value = 'Vote',
  color = 'Grey',
  selected,
  reversed,
  waiting,
  onClick,
  className,
}) => {
  const text = reversed ? '\u00a0' : waiting ? '?' : value;
  let symbol;

  if (text.startsWith('http')) {
    // Image link
    symbol = (
      <>
        &nbsp;
        <img src={text} alt="" />
      </>
    );
  } else if (text.startsWith('fa-')) {
    // Fontawesome
    console.log(text, text.slice(3));
    symbol = <FontAwesomeIcon icon={text.slice(3)} />;
  } else {
    // Just text
    symbol = text;
  }

  return (
    <CardHolder className={className} selected={selected}>
      <CardDiv
        onClick={onClick}
        color={color}
        reversed={reversed}
        waiting={waiting}
      >
        <TopCornerSymbol>{symbol}</TopCornerSymbol>
        <CenterSymbol>{symbol}</CenterSymbol>
      </CardDiv>
    </CardHolder>
  );
};

export default Card;
