import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useDelay from 'hooks/useDelay';
import ContentColumn from 'components/ContentColumn';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;

const Text = styled.h1`
  position: relative;
  color: var(--primary);
`;

const Loading = ({ children = 'Loading' }) => {
  const not_even_a_second = useDelay(500);
  const its_been_a_while = useDelay(3500);
  const its_been_a_long_while = useDelay(10000);

  if (!not_even_a_second) {
    return null;
  }

  return (
    <ContentColumn>
      <Wrapper>
        <Text>
          {its_been_a_long_while ? (
            <FontAwesomeIcon icon="ban" />
          ) : (
            <FontAwesomeIcon icon="cog" spin />
          )}
          &nbsp;{children}
        </Text>
        {its_been_a_while && !its_been_a_long_while && (
          <p>Hmmm, this is taking a while ... Let me go check!</p>
        )}
        {its_been_a_long_while && (
          <p>
            Yeah, something has gone wrong. Maybe try reloading, sorry
            ¯\_(ツ)_/¯
          </p>
        )}
      </Wrapper>
    </ContentColumn>
  );
};

export default Loading;
