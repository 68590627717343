import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

import useDecks from 'hooks/useDecks';
import Deck, { Card } from 'components/Deck';

const Section = styled.section`
  font-size: ${(props) => 10 * props.scale}px;
  display: flex;
  grid-area: cards;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: flex-end;
  margin: 0 auto;
  padding: 10rem 4rem;
  @media (min-width: 1200px) {
    margin: 0 auto;
  }
  height: auto;
  pointer-events: none;
  background: transparent;
`;

const Preview = () => {
  const { loading: loading_decks, documents: decks } = useDecks();
  const [scale, set_scale] = useState(1);
  const [deck_id] = useState(0);
  const [deck, set_deck] = useState(null);
  const section = useRef();

  useEffect(() => {
    if (loading_decks) {
      return;
    }

    set_deck(decks[deck_id]);
  }, [decks, loading_decks, deck_id]);

  useEffect(() => {
    const handleResize = () => {
      if (!section.current) {
        return;
      }
      const total = Math.max(
        960,
        section.current.getBoundingClientRect().width
      );
      const own = section.current.clientWidth;
      const new_scale = own / total;
      if (scale !== new_scale) {
        set_scale(new_scale);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [scale]);

  if (loading_decks || !deck) {
    return null;
  }

  return (
    <>
      <Section ref={section} scale={scale}>
        {deck.cards.map((card, index) => (
          <Card
            value={card.value}
            color={card.color}
            index={index}
            key={index}
            onClick={() => {}}
          />
        ))}
      </Section>
      {<Deck preview={true} deck={deck} selected={-1} onSelect={() => {}} />}
    </>
  );
};

export default Preview;
