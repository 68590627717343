import { useDispatch } from 'react-redux';

import useDocument from 'hooks/useDocument';
import { mapper } from 'models/Game';

function useGame(id, conditions = (x) => x) {
  const dispatch = useDispatch();

  const on_error = (error) => {
    console.error('hooks/useGame.js', error);
    dispatch({ type: 'ADD_ERROR', location: 'hooks/useGame.js', error });
  };

  const {
    loading,
    document,
    create: create_document,
    update,
    remove,
  } = useDocument('games', id, on_error, (ref) =>
    conditions(ref.withConverter(mapper))
  );

  const create = (game) => {
    return create_document(game, (ref) => ref.withConverter(mapper));
  };

  return { loading, document, create, update, remove };
}

export default useGame;
