import React, { useState } from 'react';
import styled from 'styled-components';

const Header = styled.header`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  font-family: 'Sorts Mill Goudy', serif;
  font-weight: 100;
  font-size: 2.5rem;
  padding: 0.5rem 2rem;
  letter-spacing: -1px;
  background-color: var(--lightgrey);
  border-bottom: 1px solid #ddd;
  background-image: radial-gradient(
    circle at top center,
    #ffffff8a 0%,
    var(--lightgrey) 70%
  );
  box-shadow: 1px 3px 0 0 #ccc, 0 0 100px 0 rgba(0, 0, 0, 0.25),
    1px 5px 5px rgba(0, 0, 0, 0.5);
  box-shadow: 1px 3px 0 0 #ccc, 0 0 100px 0 rgba(0, 0, 0, 0.25), 0 4px 0 #aaa,
    1px 5px 5px rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid #d8d8d8;
`;

const HeaderBar = ({ children }) => {
  const [position, set_position] = useState({ x: 0, y: 0 });

  return (
    <Header
      position={position}
      onMouseMove={({ screenX: x, screenY: y }) => set_position({ x, y })}
    >
      {children}
    </Header>
  );
};

export default HeaderBar;
