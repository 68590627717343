import React from 'react';
import { Route, Routes } from 'react-router-dom';

import List from './List';
import Create from './Create';
import Game from './Game';

const Page = React.memo(() => {
  return (
    <Routes>
      <Route path="/" element={<List />} />
      <Route path="new" element={<Create />} />
      <Route path=":id/*" element={<Game />} />
    </Routes>
  );
});

Page.whyDidYouRender = true;

export default Page;
