import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import marked from 'marked';

import Button, { ButtonGroup, SmallButton } from 'components/FancyButton';
import Field from 'components/Field';
import TextArea from 'components/TextArea';

const List = styled.ul`
  font-size: 1.5rem;
  list-style-type: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
`;

const ListItem = styled.li`
  padding: 1.5rem 2rem;
  background-color: #fff;
  margin: 0.5rem;
  border-radius: 3px;
  border: 1px solid #dadada;
  box-shadow: 1px 3px 4px 0 var(--grey);
  display: grid;
  grid-template-rows: 4rem 2fr 20px;
  grid-template-columns: minmax(0, 1fr);
  overflow: hidden;
  position: relative;
  &::before {
    content: '';
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      white 90%,
      white
    );
    width: 100%;
    height: 30px;
    position: absolute;
    bottom: 0;
  }
`;

const StoryDescription = styled.p`
  line-height: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  & > * {
    transform: scale(0.75);
    width: calc(150% - 4rem);
  }
`;

const Info = styled.span`
  float: right;
  font-size: 1.5rem;
  position: absolute;
  right: 2rem;
  top: 4rem;
`;

const CreateStories = ({ stories, update_stories, set_imports_open }) => {
  const [index, index_change] = useState(-1);
  const [title, title_change] = useState('');
  const [description, description_change] = useState('');

  const save = () => {
    if (index < 0) {
      update_stories([...stories, { title: title, description: description }]);
    } else {
      stories[index].title = title;
      stories[index].description = description;
      update_stories([...stories]);
    }
    cancel();
  };

  const cancel = () => {
    index_change(-1);
    title_change('');
    description_change('');
  };

  const edit = (index) => {
    index_change(index);
    title_change(stories[index].title);
    description_change(stories[index].description);
  };

  const remove = (idx) => {
    stories.splice(idx, 1);
    update_stories([...stories]);
    if (idx === index) {
      index_change(-1);
      title_change('');
      description_change('');
    }
  };

  return (
    <>
      <h2>Up for discussion</h2>

      <List>
        {stories.map(({ title, description }, index) => {
          const snippet = description
            ? description.slice(0, 100) +
              (description.length > 100 ? '...' : '')
            : '';
          return (
            <ListItem key={index}>
              <b>{title.slice(0, 30) + (title.length > 30 ? '...' : '')}</b>
              <StoryDescription
                dangerouslySetInnerHTML={{ __html: marked(snippet) }}
              />
              <ButtonGroup>
                <SmallButton center onClick={() => edit(index)}>
                  Edit
                </SmallButton>
                <SmallButton center onClick={() => remove(index)}>
                  Remove
                </SmallButton>
              </ButtonGroup>
            </ListItem>
          );
        })}
      </List>

      <Field
        value={title}
        onChange={title_change}
        placeholder="Story title"
        label="Keep the title short and to the point..."
      />
      <TextArea
        value={description}
        onChange={description_change}
        rows="5"
        placeholder="In order to ...&#10;As a ...&#10;I want to..."
        label={
          <>
            ... but don't skimp on the details.
            <Info
              className="tooltip--left"
              data-tooltip="The text in the description will be interpreted as Markdown when rendering"
            >
              <FontAwesomeIcon icon={['fab', 'markdown']} />
            </Info>
          </>
        }
      />
      <ButtonGroup>
        <Button left onClick={() => set_imports_open(true)}>
          Import
        </Button>
        {index < 0 ? (
          <Button onClick={save}>Add story</Button>
        ) : (
          <>
            <Button onClick={save}>Save changes</Button>
            <Button onClick={cancel}>Cancel</Button>
          </>
        )}
      </ButtonGroup>
    </>
  );
};

export default CreateStories;
