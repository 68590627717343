import React from 'react';
import styled from 'styled-components';

import useResizeObserver from 'hooks/useResizeObserver';
import Deck from 'components/Deck';

const Wrapper = styled.div`
  position: relative;
`;

const Selected = styled.div`
  border: 1px solid transparent;
  border-radius: 3px;
  padding: 0 2rem 2rem;
  background-color: white;
  border-color: #dadada;
  box-shadow: 1px 3px 4px 0 var(--grey);
  position: absolute;
  top: ${(props) => props.index * props.height}px;
  left: 0;
  width: 100%;
  height: ${(props) => props.height}px;

  z-index: -1;
  transition: top 250ms ease-in-out;
`;

const Box = styled.div`
  border: 1px solid transparent;
  border-radius: 3px;
  padding: 0 2rem 2rem;
`;

const DeckSelector = React.memo(({ decks, selected, onChange }) => {
  const { ref, width, height } = useResizeObserver();

  const scale = width ? width / 895 : 1;
  const selected_height = height ? height / decks.length : 285;

  if (!decks) {
    return null;
  }
  return (
    <Wrapper ref={ref}>
      {decks.map((deck, index) => (
        <Box key={deck.name} selected={selected === index}>
          <input
            type="radio"
            id={`deck${index}`}
            name="radio-group"
            defaultChecked={selected === index}
            style={{ opacity: 0 }}
          />
          <label htmlFor={`deck${index}`} onClick={() => onChange(index)}>
            <h2>{deck.name}</h2>
            <Deck scale={scale} deck={deck} preview={true} />
          </label>
        </Box>
      ))}
      <Selected height={selected_height} index={selected} />
    </Wrapper>
  );
});

DeckSelector.whyDidYouRender = true;

export default DeckSelector;
