import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useConnections from 'hooks/useConnections';
import List from 'components/List';
import Loading from 'components/Loading';
import ContentColumn from 'components/ContentColumn';
import Button, { ButtonGroup } from 'components/FancyButton';
import SectionHeader from 'components/SectionHeader';

const PlainButton = styled.button`
  border: none;
  background: transparent;
`;

const Remove = ({ processing, onClick }) => {
  return (
    <PlainButton
      href=""
      disabled={processing}
      onClick={(e) => {
        e.preventDefault();
        onClick();
      }}
    >
      {processing ? (
        <FontAwesomeIcon icon="cog" spin />
      ) : (
        <FontAwesomeIcon icon="trash-alt" />
      )}
    </PlainButton>
  );
};

const ListItem = ({ connection, remove, removing }) => {
  return (
    <List.Item>
      <Remove processing={removing} onClick={() => remove(connection)} />
      <Link to={`/games/${connection.link.id}`}>{connection.game.name}</Link>
    </List.Item>
  );
};

const GameList = () => {
  const navigate = useNavigate();
  const db = useSelector((state) => state.firebase.db);
  const user = useSelector((state) => state.user);
  const user_ref = db.collection('users').doc(user.uid);

  const [removing, set_removing] = useState({});

  const remove = async (connection) => {
    set_removing({ ...removing, [connection.id]: true });
    await connection.link.delete().catch(function (error) {
      console.error('Error removing Game: ', error);
    });
    await connection.ref.delete().catch(function (error) {
      console.error('Error removing Connection: ', error);
    });
    set_removing({ ...removing, [connection.id]: false });
  };

  const {
    loading: your_connections_loading,
    documents: your_connections,
  } = useConnections((doc_ref) =>
    doc_ref.where('owners', 'array-contains', user_ref)
  );

  const {
    loading: other_connections_loading,
    documents: other_connections,
  } = useConnections((doc_ref) =>
    doc_ref.where('participants', 'array-contains', user_ref)
  );

  if (your_connections_loading || other_connections_loading) {
    return <Loading>Loading games</Loading>;
  }

  return (
    <ContentColumn>
      <SectionHeader tooltip="These are the games that you have created or been assigned as owner to.">
        Your games
      </SectionHeader>
      {your_connections.length === 0 ? (
        <p>You have not created any games yet.</p>
      ) : (
        <List>
          {your_connections.map((connection) => (
            <ListItem
              key={connection.id}
              connection={connection}
              remove={remove}
              removing={removing[connection.id]}
            />
          ))}
        </List>
      )}

      <SectionHeader tooltip="These are the games that you have been invited to or participated in.">
        Other games
      </SectionHeader>
      {other_connections.length === 0 ? (
        <p>You are not part of any other games yet.</p>
      ) : (
        <List>
          {other_connections.map((connection) => (
            <ListItem
              key={connection.id}
              connection={connection}
              remove={remove}
              removing={removing[connection.id]}
            />
          ))}
        </List>
      )}
      <ButtonGroup main>
        <Button onClick={() => navigate('/games/new')}>
          New game <FontAwesomeIcon icon="plus" />
        </Button>
      </ButtonGroup>
    </ContentColumn>
  );
};

export default GameList;
