import styled, { css } from 'styled-components';

const disabled = css`
  border-left-color: var(--darkgrey);
  &::before {
    background-color: var(--grey);
  }
`;

const Fieldset = styled.fieldset`
  position: relative;
  border: none;
  margin: ${(props) => (props.label ? '0 0 0 3rem' : '0')};
  padding: 0;
  border-left: ${(props) =>
    props.label ? '5px solid var(--primary)' : 'none'};
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  padding-left: ${(props) => (props.label ? '3rem' : '0')};
  margin-bottom: ${(props) => (props.label ? '5rem' : '0')};
  transition: border-color 500ms;

  &::before {
    display: ${(props) => (props.label ? 'block' : 'none')};
    content: attr(label);
    position: absolute;
    top: -8px;
    left: calc(-3rem);
    color: var(--offwhite);
    font-size: 2.5rem;
    font-family: 'Sorts Mill Goudy', serif;
    width: 3rem;
    text-align: center;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 8px;
    box-shadow: 1px 3px 4px 0 #555;
    background-color: var(--primary-light);
    background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0.25) 0%,
      transparent 20%
    );
    transition: background-color 500ms;
    clip-path: polygon(0 0, 100% 0, 100% 120%, 0 120%);
  }
  ${(props) => (props.done ? null : disabled)};
`;

export default Fieldset;
