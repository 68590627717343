import { createLogic } from 'redux-logic';

export const AUTH_STATE_CHANGED = '@auth/state-changed';
export const USER_LOADED = '@session/user_loaded';
export const USER_UNLOAD = '@session/user_unload';
export const USER_UPDATE = '@user/update';
export const USER_SET_NAME = '@user/set_name';

const auth_reply_from_firebase = createLogic({
  type: AUTH_STATE_CHANGED,
  async process({ getState, action }, dispatch, done) {
    const state = getState();
    const state_user = state.user || { name: null };

    if (!action.user) {
      dispatch({ type: USER_UNLOAD });
      done();
      return;
    }

    const user = await state.firebase.db
      .collection('users')
      .doc(action.user.uid)
      .get()
      .catch((error) =>
        dispatch({
          type: 'ADD_ERROR',
          location: 'flows/user auth_reply_from_firebase get_user',
          error,
        })
      );

    let user_props = { ...state_user };

    if (!action.user.isAnonymous) {
      //console.log('login via IDP');
      user_props.name = action.user.displayName;
      user_props.photo_url = action.user.photoURL;
      user_props.email = action.user.email;
      if (!user.exists) {
        //console.log('first time login via IDP');
        state.firebase.db
          .collection('users')
          .doc(action.user.uid)
          .set({ name: state_user.name })
          .catch((error) =>
            dispatch({
              type: 'ADD_ERROR',
              location: 'flows/user auth_reply_from_firebase create_user',
              error,
            })
          );
      }
    } else if (user.exists) {
      //console.log('anonymous login, existing user');
      user_props = user.data();
    } else {
      //console.log('anonymous login, new user');
      user_props = state_user;
      state.firebase.db
        .collection('users')
        .doc(action.user.uid)
        .set({ name: state_user.name })
        .catch((error) =>
          dispatch({
            type: 'ADD_ERROR',
            location: 'flows/user auth_reply_from_firebase create_user',
            error,
          })
        );
    }

    //console.log(user);
    //console.log(action.user);
    //console.log(user_props);

    dispatch({
      type: USER_LOADED,
      user: {
        ...user_props,
        uid: action.user.uid,
        is_anonymous: action.user.isAnonymous,
      },
    });

    done();
  },
});

const user_update = createLogic({
  type: USER_UPDATE,
  async validate({ getState, action: { type, ...attributes } }, allow, reject) {
    const state = getState();

    try {
      state.firebase.db
        .collection('users')
        .doc(state.user.uid)
        .set(attributes, { merge: true })
        .then(() => allow({ type, attributes }))
        .catch((error) => reject({ type: 'ADD_ERROR', error }));
    } catch (error) {
      //console.log('Error updating user:', error);
      reject({ type: 'ADD_ERROR', error });
    }
  },
});

export default [auth_reply_from_firebase, user_update];
