import { useDispatch } from 'react-redux';

import useCollection from 'hooks/useCollection';

function useTeams(conditions = (x) => x) {
  const dispatch = useDispatch();

  const on_error = (error) => {
    console.error('hooks/useTeams.js', error);
    dispatch({
      type: 'ADD_ERROR',
      location: 'hooks/useTeams.js',
      error,
    });
  };

  return useCollection('teams', on_error, conditions);
}

export default useTeams;
