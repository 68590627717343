import { useDispatch } from 'react-redux';

import useDocument from 'hooks/useDocument';

function useConnection(id, conditions = (x) => x) {
  const dispatch = useDispatch();

  const on_error = (error) => {
    console.error(error);
    dispatch({ type: 'ADD_ERROR', location: 'hooks/useConnection.js', error });
  };

  return useDocument('connections', id, on_error, conditions);
}

export default useConnection;
