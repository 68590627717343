import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

const InfoHolder = styled.span.attrs((props) => ({
  'data-tooltip': props.tooltip,
  className: classNames(props.className, {
    'tooltip--top': props.top && props.tooltip,
    'tooltip--right': props.right && props.tooltip,
    'tooltip--bottom': props.bottom && props.tooltip,
    'tooltip--left': props.left && props.tooltip,
  }),
}))`
  float: right;
  font-family: 'Open Sana', sans-serif;
  font-weight: 100;
  font-size: 75%;
`;

const Info = ({ ...props }) => (
  <InfoHolder {...props}>
    <FontAwesomeIcon icon="question-circle" />
  </InfoHolder>
);

export default Info;
