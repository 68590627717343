import styled from 'styled-components';

import { SmallButton } from 'components/FancyButton';

const Chip = styled(SmallButton)`
  font-family: "Open Sans", sans-serif;
  ${(props) => props.ok && `--chip-color: var(--ok-notice);`}
  ${(props) => props.warning && `--chip-color: var(--warning-notice);`}
  ${(props) => props.danger && `--chip-color: var(--danger-notice);`}
  ${(props) => props.color && `--chip-color: ${props.color};`}
  top: -0.25rem;
  background-color: var(--chip-color);
  border-left: solid 1px #0001;
  background-image: none;

  box-shadow: inset -1px 1px 0px #0003, 0px 1px 0px #0005,
    0px 1px 0px var(--chip-color), 0px 2px 0px 0px #000a,
    0px 2px 0px 0px var(--chip-color), 1px 1px 2px #555;
  color: var(--offwhite);
  pointer-events: none;
  margin: 0 1rem;
  line-height: 1rem;
  padding-top: .4rem;
`;

export default Chip;
