import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import useTeams from 'hooks/useTeams';
import ContentColumn from 'components/ContentColumn';
import Loading from 'components/Loading';
import SectionHeader from 'components/SectionHeader';

const Section = styled.section`
  display: grid;
  grid-template-rows: 1fr;
`;

const Page = () => {
  const user = useSelector((state) => state.user);
  const db = useSelector((state) => state.firebase.db);
  const { loading, documents: teams } = useTeams((ref) =>
    ref.where('members', 'array-contains', db.collection('users').doc(user.uid))
  );

  if (loading) {
    return <Loading>Loading teams and invitations</Loading>;
  }

  return (
    <ContentColumn>
      <SectionHeader tooltip="These are the teams that you have joined.">
        Teams
      </SectionHeader>
      <Section>
        {teams.map((team, index) => (
          <div key={index}>{team.id}</div>
        ))}
      </Section>
      <SectionHeader tooltip="These are the teams that you have been invited to.">
        Invitations
      </SectionHeader>
    </ContentColumn>
  );
};

export default Page;
