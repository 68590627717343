import Firebase from 'firebase/app';
import { v1 as uuid } from 'uuid';

// Firestore returns a map of index -> story, we want a pure array.
const stories_from_firestore = (stories) => {
  let converted_stories = [];

  Object.entries(stories).forEach(([index, story]) => {
    converted_stories.push(story);
  });

  return converted_stories;
};

// Conversly we need to convert the stories back from an array to an Object
// when exporting to Firestore
const stories_to_firestore = (stories) => {
  let converted_stories = {};

  stories.forEach((story, index) => (converted_stories[index] = story));

  return converted_stories;
};

export default class Game {
  constructor({ id, by, name, deck, stories, ...options }) {
    ({
      players: this.players = { [by.uid]: { name: by.name } },
      owner: this.owner = by.uid,
      current_story: this.current_story = -1,
      public: this.public = true,
      created_at: this.created_at,
      scores: this.scores = {},
    } = options);

    const instansiating_from_firebase = typeof id !== 'undefined';

    this.id = instansiating_from_firebase ? id : uuid();
    this.name = name;
    this.deck = { ...deck };
    delete this.deck.ref;
    if (instansiating_from_firebase) {
      this.stories = stories_from_firestore(stories);
    } else {
      this.stories = stories.map((story) => {
        if (story.votes) {
          return story;
        }

        return { ...story, votes: {} };
      });
    }
  }

  // Count only players that voted as active in the game, not the ones that
  // might have been observing or dropped before voting etc.
  active_players() {
    const players = {};

    this.stories.forEach((story) => {
      Object.keys(story.votes).forEach((uid) => (players[uid] = true));
    });

    return Object.keys(players);
  }
}

export const mapper = {
  toFirestore: function (game) {
    console.log(game);
    const object = JSON.parse(JSON.stringify(game));

    object.stories = stories_to_firestore(game.stories);

    if (!object.created_at) {
      object.created_at = Firebase.firestore.FieldValue.serverTimestamp();
    }

    return object;
  },
  fromFirestore: function (snapshot, options) {
    const data = snapshot.data(options);
    return new Game({ id: snapshot.id, ...data });
  },
};
