import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import Dialog from 'components/Dialog';
import InlineNavRocker from 'components/InlineNavRocker';

const ErrorMessage = styled.pre`
  overflow-y: scroll;
  max-height: 15rem;
  color: #bd0000;
  background-color: #eee;
  padding: 1rem 2rem;
  width: calc(100% + 4rem);
  border-radius: 3px;
  margin-left: -2rem;
  margin-top: 3rem;
`;

const ErrorDialog = () => {
  const errors = useSelector((state) => state.errors);
  const [current_error, set_current_error] = useState(0);
  const dispatch = useDispatch();

  if (errors.length === 0) {
    return null;
  }

  return (
    <Dialog
      onClose={() => {
        set_current_error(0);
        dispatch({ type: 'CLEAR_ERRORS' });
      }}
    >
      <h1>Oops ... (✖╭╮✖)</h1>
      <h3>Unfortunately you got hit by an error, sorry ...</h3>
      <p>
        It <em>might</em> help to reload the page. If it doesn't then rest
        assured that we already know about the snafu and will work to fix the
        bug as soon as possible. Here is some more info that might help explain
        what went wrong:
      </p>
      <p>
        <InlineNavRocker
          position={current_error + 1}
          length={errors.length}
          previous={() => set_current_error(current_error - 1)}
          next={() => set_current_error(current_error + 1)}
        />
        At
        {errors[current_error].location ? (
          <code> {errors[current_error].location} </code>
        ) : (
          ' an unknown location '
        )}
        in our code we got the error:
      </p>
      <ErrorMessage>{errors[current_error].message}</ErrorMessage>
    </Dialog>
  );
};

export default ErrorDialog;
